
/* app/components/HeaderSE/deferrals/countrySelector/index.scss */
@import 'app/stylesheets/mixins/stretchToFullWidth.scss';

.se2--header-se--country-selector {
  

& {
  @include stretch-to-full-width();

  z-index: #{z('fixed')};
  display: flex;
  flex-direction: column;
  block-size: 100vh;
  overflow: auto;
  background: var(--color-white);
  // Due to Header has pointer-events: none
  pointer-events: auto;

  @media #{breakpoint('xl')} {
    @include stretch-to-full-width($block-start: 40px);

    block-size: calc(100vh - 40px);
  }

  .head {
    display: flex;
    justify-content: space-between;
    padding-inline: var(--page-content-sorrounding-gap);

    &.shadow {
      box-shadow: 0 1px 10px 0 rgba(51, 51, 51, 0.15);
    }

    &-title {
      margin-block-start: 15px;
      margin-block-end: 15px;
      font-size: 25px;
      line-height: 35px;

      @media #{breakpoint('xl')} {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }

  .button-close {
    position: static;
  }

  .body {
    block-size: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .section {
      html & {
        border-block-end: 1px solid var(--color-footer-gray);
        padding-block-start: 20px;
        padding-block-end: 20px;
        padding-inline-start: var(--page-content-sorrounding-gap);
        padding-inline-end: var(--page-content-sorrounding-gap);

        @media #{breakpoint('xl')} {
          border-block-end: 0;
          padding-block-start: 15px;
          padding-block-end: 15px;
        }
      }

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        line-height: 25px;
        color: var(--color-life-green);

        @media #{breakpoint('xl')} {
          margin-block-end: 20px;
          font-size: 22px;
          line-height: 30px;
        }
      }

      &.open .title {
        margin-block-end: 20px;
      }

      .toggle-buttons-wrap {
        @media #{breakpoint('xl')} {
          display: none;
        }

        svg {
          block-size: 16px;
          inline-size: 16px;
          color: var(--color-light-gray);
        }
      }

      .icon-close {
        display: none;
      }

      .icon-open {
        display: block;
      }

      &.open {
        background: var(--color-bg-gray);

        @media #{breakpoint('xl')} {
          background: none;
        }

        .icon-open {
          display: none;
        }

        .icon-close {
          display: block;
        }
      }

      .link-list {
        display: none;
        column-count: 2;
        column-gap: 20px;

        a {
          inline-size: 100%;
          margin-block-end: 10px;
        }

        @media #{breakpoint('xl')} {
          display: block;
          column-count: 4;
          text-align: start;
        }
      }

      &.open .link-list {
        display: block;
        text-align: start;
      }
    }
  }
}

}
