
/* app/stylesheets/mixins/visuallyhidden.scss */
%visuallyhidden {
  position: absolute;
  block-size: 1px;
  inline-size: 1px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

