
/* app/components/HeaderSE/index.scss */
@import 'app/partials/countrySelectorSection';
@import 'app/partials/singleLink';
@import './partials/b2bB2cToggle';
@import 'deferrals/brandSelector/index';
@import './partials/commonPart';
@import 'deferrals/countrySelector/index';
@import './partials/geolocationBanner';
@import './partials/megaMenu';
@import './partials/menuSectionTopLink';
@import './partials/metabar';
@import './partials/metabarButton';
@import './partials/metabarLink';
@import './partials/predictiveSearch';
@import './partials/promoBanner';
@import './partials/search';
@import './partials/signInForAPC';
@import './partials/skipNavigation';
@import './partials/stockPrice';
@import './partials/userMenuForAPC';
@import './partials/loginBanner';

.se2--header-se {
  

@keyframes hideList {
  0% {
    max-block-size: 1000px;
    overflow: hidden;
  }

  100% {
    max-block-size: 0;
  }
}

@keyframes showList {
  0% {
    max-block-size: 0;
    overflow: hidden;
  }

  100% {
    max-block-size: 1000px;
  }
}

& {
  --color-bg-gray-new: #f3f2f2;
  --color-black: #000;
  --color-life-green: #3dcd58;
  --color-white: #fff;
  --color-text-gray: #333;
  --color-sky-blue: #42b4e6;

  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: #{z('fixed')};
  inline-size: 100vw;
  // Header has to has pointer-events: none, because
  // it doesn't change own height after gsap animation end,
  // and thus overlaps first sticky component under it.
  // This is to make first sticky component under header clickable.
  pointer-events: none;

  .visuallyhidden {
    position: absolute;
    height: 1px;
    width: 1px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
  }

  .hide {
    display: none !important;
  }

  li {
    list-style: none;
  }

  .se2-grey-blue-link {
    text-decoration: none;
    color: var(--color-text-gray);
  }

  .size20x20 {
    block-size: 20px;
    inline-size: 20px;
  }

  &.expanded-menu {
    z-index: #{z('menu')};
  }

  &_header-placeholder {
    block-size: var(--header-height-mobile);
    min-block-size: 121px;

    @media #{breakpoint('xl')} {
      block-size: var(--header-height-desktop);
      min-block-size: 152px;
    }
  }

  &.transparent ~ &_header-placeholder {
    --header-height-desktop: 40px;

    @media #{breakpoint('xl')} {
      block-size: var(--metabar-height);
      min-block-size: initial;
    }
  }

  &.transparent ~ main > .se2--banner-full-image-carousel,
  &.transparent ~ main > .se2--banner-full-image-carousel .fixed-background,
  &.transparent ~ main > .se2--banner-full-image-carousel .fixed-background img,
  &.transparent ~ main > .se2--banner-full-image-carousel .fixed-background video,
  &.transparent ~ main > .se2--banner-full-image-carousel .fixed-background::after {
    @media #{breakpoint('xl')} {
      block-size: calc(var(--window-height-inner) - var(--metabar-height));
    }
  }

  &:not(.transparent) ~ main > [class^='se2--']:not(.se2--banner-full-image-carousel):not(.se2--navigation-customer-journey-with-cta):not(.se2--content-small-square-image):not(.se2--navigation-customer-journey) {
    margin-block-start: var(--component-block-indent);
  }

  &:not(.transparent) ~ main > [class^='se2--']:not(.se2--banner-full-image-carousel):not(.se2--navigation-customer-journey-with-cta):not(.se2--content-small-square-image):not(.se2--navigation-customer-journey) ~ [class^='se2--'] {
    margin-block-start: 0;
  }
}

}
