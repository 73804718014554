
/* app/components/HeaderSE/partials/skipNavigation/index.scss */

.se2--header-se--skip-navigation {
  & > button {
  position: absolute;
  z-index: #{z('hotspot')};
  block-size: 1px;
  inline-size: 1px;
  // Due to Header has pointer-events: none
  pointer-events: auto;
  clip: rect(0 0 0 0);

  &:focus {
    inset-block-start: 0;
    inset-inline-start: 50%;
    block-size: auto;
    inline-size: auto;
    border: none;
    border-radius: 0 0 8px 8px;
    padding: 4px 12px;
    margin: 0;
    text-align: center;
    color: var(--color-white);
    background: var(--color-text-gray);
    transform: translate(-50%);
    clip: auto;
  }
}

}
