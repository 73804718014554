
/* app/partials/countrySelectorSection/index.scss */

.se2--country-selector-section {
  & {
  html & {
    border-block-end: 1px solid var(--color-footer-gray);
    padding-block-start: 20px;
    padding-block-end: 20px;
    padding-inline-start: var(--page-content-sorrounding-gap);
    padding-inline-end: var(--page-content-sorrounding-gap);

    @media #{breakpoint('xl')} {
      border-block-end: 0;
      padding-block-start: 15px;
      padding-block-end: 15px;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    line-height: 25px;
    color: var(--color-life-green);

    .se2-accessibility & {
      color: var(--color-text-gray);
    }

    @media #{breakpoint('xl')} {
      margin-block-end: 20px;
      font-size: 22px;
      line-height: 30px;
    }
  }

  &.open .title {
    margin-block-end: 20px;
  }

  .toggle-buttons-wrap {
    @media #{breakpoint('xl')} {
      display: none;
    }

    svg {
      block-size: 16px;
      inline-size: 16px;
      color: var(--color-light-gray);
    }
  }

  .icon-close {
    display: none;
  }

  .icon-open {
    display: block;
  }

  &.open {
    background: var(--color-bg-gray);

    @media #{breakpoint('xl')} {
      background: none;
    }

    .icon-open {
      display: none;
    }

    .icon-close {
      display: block;
    }
  }

  .link-list {
    display: none;
    column-count: 2;
    column-gap: 20px;

    a {
      inline-size: 100%;
      margin-block-end: 10px;
    }

    @media #{breakpoint('xl')} {
      display: block;
      column-count: 4;
      text-align: start;
    }
  }

  &.open .link-list {
    display: block;
    text-align: start;
  }
}

}
