
/* app/components/HeaderSE/partials/promoBanner/index.scss */

.se2--header-se--promo-banner {
  & {
  --color-white: #fff;
  --color-sky-blue: #42b4e6;

  &.notification-container {
    position: fixed;
    inset-block-start: 120px;
    inset-inline: 0;
    z-index: #{z('hotspot')};
    display: block;
    background: transparent;
    opacity: 1;
    transition: inset-block-start 0.25s ease-out, opacity 0.25s;

    @media #{breakpoint('xl')} {
      display: none;
    }
  }

  .notification-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    block-size: 100%;
    inline-size: 100vw;
    padding-block: 12px;
    padding-inline: 16px;
    background-color: var(--color-white);
  }

  .buttons-container {
    display: flex;
    align-items: center;

    .se2-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      border: 0;
      border-radius: 5px;
      padding: 0 20px;
      text-align: center;
      text-decoration: none;
      color: var(--color-white);
      background-color: var(--color-sky-blue);
      cursor: pointer;
    }
  }

  .notification-btn {
    max-inline-size: 120px;
    margin-inline-end: 14px;

    svg {
      display: none;
    }
  }

  .svg-container {
    block-size: 40px;
    max-inline-size: 132px;
    margin-inline-end: 17px;

    img {
      block-size: 40px;
      inline-size: 132px;
    }
  }

  .close-button {
    cursor: pointer;

    &.se2-icon-close::before {
      font-size: 120%;
      color: var(--color-dark-gray);
    }

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 1;
    }

    &:disabled {
      opacity: 0.3;
    }
  }
}

}
