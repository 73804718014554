
/* app/components/HeaderSE/partials/b2bB2cToggle/index.scss */

.se2--header-se--b2b-b2c-toggle {
  /* --- Toggle B2B and B2C --- */

$item-border: 1px solid var(--color-footer-gray);

& {
  display: flex;
  block-size: 48px;
  margin: 0;

  li {
    flex-grow: 1;
  }

  a,
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    block-size: 100%;
    padding: 0 6px;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
  }

  a {
    text-align: center;
    color: var(--color-text-gray);
    background: var(--color-white);

    @media #{breakpoint('xl')} {
      border-block-end: $item-border;
    }

    &:hover {
      color: var(--color-text-gray);
    }
  }

  button {
    inline-size: 100%;
    border-block-start: none;
    border-block-end: 2px solid var(--color-life-green);
    border-inline-start: none;
    border-inline-end: none;
    margin: 0;
    background-color: var(--color-bg-gray);

    @media #{breakpoint('xl')} {
      border: none;
      border-block-start: 2px solid var(--color-life-green);
    }
  }

  @media #{breakpoint('xl')} {
    position: relative;
    flex-direction: row;
    block-size: 100%;

    button {
      block-size: 40px; // according to design
      padding-block-start: 5px;
      padding-block-end: 10px;
      padding-inline-start: 15px;
      padding-inline-end: 15px;
      background: var(--color-bg-gray);
      cursor: pointer;
    }

    a {
      justify-content: flex-start;
      border: $item-border;
      padding-block-start: 10px;
      padding-block-end: 10px;
      padding-inline-start: 15px;
      padding-inline-end: 15px;
    }

    &.open &-link {
      visibility: visible;
    }

    &-link {
      order: 0;
      visibility: visible;

      a {
        justify-content: center;
        border: $item-border;
        border-block-start: none;
        border-block-end: none;
        padding-block-start: 10px;
        padding-block-end: 10px;
        padding-inline-start: 15px;
        padding-inline-end: 15px;
      }
    }

    li:not(:last-child) a,
    li:not(:last-child) button {
      block-size: -webkit-fill-available;
      border-inline-end: none;
    }
  }
}

/* --- // Toggle B2B and B2C // --- */

}
