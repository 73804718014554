/*! Built from branch "HEAD" for production at Mon, 20 Mar 2023 09:06:16 GMT */
/* app/components/HeaderSE/index.scss */
/* app/partials/countrySelectorSection/index.scss */
html[dir="ltr"] .se2--country-selector-section {
  padding-left: var(--page-content-sorrounding-gap);
}
html[dir="rtl"] .se2--country-selector-section {
  padding-right: var(--page-content-sorrounding-gap);
}
html[dir="ltr"] .se2--country-selector-section {
  padding-right: var(--page-content-sorrounding-gap);
}
html[dir="rtl"] .se2--country-selector-section {
  padding-left: var(--page-content-sorrounding-gap);
}
html .se2--country-selector-section {
  border-bottom: 1px solid var(--color-footer-gray);
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 1200px) {
  html .se2--country-selector-section {
    border-bottom: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.se2--country-selector-section .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  line-height: 25px;
  color: var(--color-life-green);
}

.se2-accessibility .se2--country-selector-section .title {
  color: var(--color-text-gray);
}

@media (min-width: 1200px) {
  .se2--country-selector-section .title {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 30px;
  }
}

.se2--country-selector-section.open .title {
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .se2--country-selector-section .toggle-buttons-wrap {
    display: none;
  }
}

.se2--country-selector-section .toggle-buttons-wrap svg {
  height: 16px;
  width: 16px;
  color: var(--color-light-gray);
}

.se2--country-selector-section .icon-close {
  display: none;
}

.se2--country-selector-section .icon-open {
  display: block;
}

.se2--country-selector-section.open {
  background: var(--color-bg-gray);
}

@media (min-width: 1200px) {
  .se2--country-selector-section.open {
    background: none;
  }
}

.se2--country-selector-section.open .icon-open {
  display: none;
}

.se2--country-selector-section.open .icon-close {
  display: block;
}

.se2--country-selector-section .link-list {
  display: none;
  column-count: 2;
  column-gap: 20px;
}

.se2--country-selector-section .link-list a {
  width: 100%;
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--country-selector-section .link-list {
    text-align: left;
  }
  [dir="rtl"] .se2--country-selector-section .link-list {
    text-align: right;
  }
  .se2--country-selector-section .link-list {
    display: block;
    column-count: 4;
  }
}

[dir="ltr"] .se2--country-selector-section.open .link-list {
  text-align: left;
}

[dir="rtl"] .se2--country-selector-section.open .link-list {
  text-align: right;
}

.se2--country-selector-section.open .link-list {
  display: block;
}

/* app/partials/singleLink/index.scss */
/* app/stylesheets/mixins/stretchToFullWidth.scss */
/**
 * Mixin stretches element to whole available width of the container
 *
 *  @param {number} $block-start
 *  @param {number} $inline-end
 *  @param {number} $block-end
 *  @param {number} $inline-start
 *  @param {string} $position
 *
 *  Example:
 *    @include stretch-to-full-width();
 *    @include stretch-to-full-width($position: static, $block-start: unset);
 *    @include stretch-to-full-width($block-start: 100px, $inline-end: 200px, $block-end: 30px, $inline-start: 60px);
 *
 */
.se2--single-link {
  display: inline-flex;
}

[dir="ltr"] .se2--single-link.stretched-link::after {
  left: 0;
}

[dir="rtl"] .se2--single-link.stretched-link::after {
  right: 0;
}

[dir="ltr"] .se2--single-link.stretched-link::after {
  right: 0;
}

[dir="rtl"] .se2--single-link.stretched-link::after {
  left: 0;
}

.se2--single-link.stretched-link::after {
  position: absolute;
  top: 0;
  bottom: 0;
  content: '';
  display: block;
}

.se2--single-link.nowrap-underline:hover span.nowrap {
  text-decoration: underline;
}

/* app/components/HeaderSE/partials/b2bB2cToggle/index.scss */
.se2--header-se--b2b-b2c-toggle {
  /* --- Toggle B2B and B2C --- */
  /* --- // Toggle B2B and B2C // --- */
}

.se2--header-se--b2b-b2c-toggle {
  display: flex;
  height: 48px;
  margin: 0;
}

.se2--header-se--b2b-b2c-toggle li {
  flex-grow: 1;
}

.se2--header-se--b2b-b2c-toggle a,
.se2--header-se--b2b-b2c-toggle button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 6px;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
}

.se2--header-se--b2b-b2c-toggle a {
  text-align: center;
  color: var(--color-text-gray);
  background: var(--color-white);
}

@media (min-width: 1200px) {
  .se2--header-se--b2b-b2c-toggle a {
    border-bottom: 1px solid var(--color-footer-gray);
  }
}

.se2--header-se--b2b-b2c-toggle a:hover {
  color: var(--color-text-gray);
}

[dir="ltr"] .se2--header-se--b2b-b2c-toggle button {
  border-left: none;
}

[dir="rtl"] .se2--header-se--b2b-b2c-toggle button {
  border-right: none;
}

[dir="ltr"] .se2--header-se--b2b-b2c-toggle button {
  border-right: none;
}

[dir="rtl"] .se2--header-se--b2b-b2c-toggle button {
  border-left: none;
}

.se2--header-se--b2b-b2c-toggle button {
  width: 100%;
  border-top: none;
  border-bottom: 2px solid var(--color-life-green);
  margin: 0;
  background-color: var(--color-bg-gray);
}

@media (min-width: 1200px) {
  .se2--header-se--b2b-b2c-toggle button {
    border: none;
    border-top: 2px solid var(--color-life-green);
  }
}

@media (min-width: 1200px) {
  .se2--header-se--b2b-b2c-toggle {
    position: relative;
    flex-direction: row;
    height: 100%;
  }
  [dir="ltr"] .se2--header-se--b2b-b2c-toggle button {
    padding-left: 15px;
  }
  [dir="rtl"] .se2--header-se--b2b-b2c-toggle button {
    padding-right: 15px;
  }
  [dir="ltr"] .se2--header-se--b2b-b2c-toggle button {
    padding-right: 15px;
  }
  [dir="rtl"] .se2--header-se--b2b-b2c-toggle button {
    padding-left: 15px;
  }
  .se2--header-se--b2b-b2c-toggle button {
    height: 40px;
    padding-top: 5px;
    padding-bottom: 10px;
    background: var(--color-bg-gray);
    cursor: pointer;
  }
  [dir="ltr"] .se2--header-se--b2b-b2c-toggle a {
    padding-left: 15px;
  }
  [dir="rtl"] .se2--header-se--b2b-b2c-toggle a {
    padding-right: 15px;
  }
  [dir="ltr"] .se2--header-se--b2b-b2c-toggle a {
    padding-right: 15px;
  }
  [dir="rtl"] .se2--header-se--b2b-b2c-toggle a {
    padding-left: 15px;
  }
  .se2--header-se--b2b-b2c-toggle a {
    justify-content: flex-start;
    border: 1px solid var(--color-footer-gray);
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .se2--header-se--b2b-b2c-toggle.open .se2--header-se--b2b-b2c-toggle-link {
    visibility: visible;
  }
  .se2--header-se--b2b-b2c-toggle-link {
    order: 0;
    visibility: visible;
  }
  [dir="ltr"] .se2--header-se--b2b-b2c-toggle-link a {
    padding-left: 15px;
  }
  [dir="rtl"] .se2--header-se--b2b-b2c-toggle-link a {
    padding-right: 15px;
  }
  [dir="ltr"] .se2--header-se--b2b-b2c-toggle-link a {
    padding-right: 15px;
  }
  [dir="rtl"] .se2--header-se--b2b-b2c-toggle-link a {
    padding-left: 15px;
  }
  .se2--header-se--b2b-b2c-toggle-link a {
    justify-content: center;
    border: 1px solid var(--color-footer-gray);
    border-top: none;
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  [dir="ltr"] .se2--header-se--b2b-b2c-toggle li:not(:last-child) a,[dir="ltr"] 
  .se2--header-se--b2b-b2c-toggle li:not(:last-child) button {
    border-right: none;
  }
  [dir="rtl"] .se2--header-se--b2b-b2c-toggle li:not(:last-child) a,[dir="rtl"] 
  .se2--header-se--b2b-b2c-toggle li:not(:last-child) button {
    border-left: none;
  }
  .se2--header-se--b2b-b2c-toggle li:not(:last-child) a,
  .se2--header-se--b2b-b2c-toggle li:not(:last-child) button {
    height: -webkit-fill-available;
  }
}

/* app/components/HeaderSE/deferrals/brandSelector/index.scss */
/* app/stylesheets/mixins/stretchToFullWidth.scss */
/**
 * Mixin stretches element to whole available width of the container
 *
 *  @param {number} $block-start
 *  @param {number} $inline-end
 *  @param {number} $block-end
 *  @param {number} $inline-start
 *  @param {string} $position
 *
 *  Example:
 *    @include stretch-to-full-width();
 *    @include stretch-to-full-width($position: static, $block-start: unset);
 *    @include stretch-to-full-width($block-start: 100px, $inline-end: 200px, $block-end: 30px, $inline-start: 60px);
 *
 */
[dir="ltr"] .se2--header-se--brand-selector {
  left: 0;
}
[dir="rtl"] .se2--header-se--brand-selector {
  right: 0;
}
[dir="ltr"] .se2--header-se--brand-selector {
  right: 0;
}
[dir="rtl"] .se2--header-se--brand-selector {
  left: 0;
}
.se2--header-se--brand-selector {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 60;
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  pointer-events: auto;
}

@media (min-width: 1200px) {
  .se2--header-se--brand-selector {
    position: unset;
  }
}

.se2--header-se--brand-selector .list-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0 1px 10px 0 rgba(51, 51, 51, 0.15);
}

.se2--header-se--brand-selector .list-title {
  margin: 0;
  font-weight: 700;
  color: var(--color-text-gray);
}

.se2--header-se--brand-selector .list-back-btn, .se2--header-se--brand-selector .list-close-btn {
  display: block;
  border: none;
  padding: 0;
  background: transparent;
}

.se2--header-se--brand-selector .list-back-btn:hover, .se2--header-se--brand-selector .list-close-btn:hover {
  cursor: pointer;
}

.se2--header-se--brand-selector .list-back-btn:hover.se2-icon-close::before, .se2--header-se--brand-selector .list-back-btn:hover.se2-icon-arrow-common-wide::after, .se2--header-se--brand-selector .list-close-btn:hover.se2-icon-close::before, .se2--header-se--brand-selector .list-close-btn:hover.se2-icon-arrow-common-wide::after {
  opacity: 0.8;
}

.se2--header-se--brand-selector .list-back-btn.se2-icon-arrow-common-wide::after, .se2--header-se--brand-selector .list-back-btn.se2-icon-close::before, .se2--header-se--brand-selector .list-close-btn.se2-icon-arrow-common-wide::after, .se2--header-se--brand-selector .list-close-btn.se2-icon-close::before {
  font-size: 120%;
  color: var(--color-text-gray);
  opacity: 0.5;
}

.se2--header-se--brand-selector .list-back-btn.se2-icon-arrow-common-wide::after, .se2--header-se--brand-selector .list-close-btn.se2-icon-arrow-common-wide::after {
  width: 22px;
  font-size: 220%;
  line-height: 15px;
  transform: rotate(180deg);
}

[dir="rtl"] .se2--header-se--brand-selector .list-back-btn.se2-icon-arrow-common-wide::after,[dir="rtl"]  .se2--header-se--brand-selector .list-close-btn.se2-icon-arrow-common-wide::after {
  transform: rotate(360deg);
}

.se2--header-se--brand-selector .list-back-btn {
  height: 18px;
  width: 15px;
}

.se2--header-se--brand-selector .list-close-btn {
  height: 18px;
  width: 15px;
}

.se2--header-se--brand-selector .brand-list {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 1px;
}

@media (min-width: 1200px) {
  .se2--header-se--brand-selector .brand-list {
    position: absolute;
    top: 40px;
    z-index: 60;
    display: none;
    flex-grow: unset;
    flex-direction: row;
    flex-wrap: wrap;
    height: unset;
    min-height: 70px;
    max-height: 0;
    min-width: 70px;
    margin: 0;
    overflow: hidden;
    background: var(--color-white);
    animation: 0.3s ease-in hideList;
  }
}

.se2--header-se--brand-selector .brand-list.open {
  display: flex;
  max-height: 1000px;
  box-shadow: rgba(6, 11, 45, 0.25) 0 3px 7px 1px;
}

@media (min-width: 1200px) {
  .se2--header-se--brand-selector .brand-list.open {
    overflow: inherit;
    animation: 0.3s ease-in showList;
  }
}

.se2--header-se--brand-selector .brand-list[data-mobile-size='xs'] img {
  height: 50px;
}

.se2--header-se--brand-selector .brand-list[data-mobile-size='s'] img {
  height: 35px;
}

.se2--header-se--brand-selector .brand-list[data-mobile-size='m'] img {
  height: 25px;
}

.se2--header-se--brand-selector .brand-list[data-mobile-size='l'] img {
  height: 20px;
}

@media (min-width: 1200px) {
  .se2--header-se--brand-selector .brand-list[data-desktop-size='s'] {
    width: 240px;
  }
  .se2--header-se--brand-selector .brand-list[data-desktop-size='s'] li {
    width: 50%;
  }
  .se2--header-se--brand-selector .brand-list[data-desktop-size='m'] {
    width: 360px;
  }
  .se2--header-se--brand-selector .brand-list[data-desktop-size='m'] li {
    width: 120px;
  }
  .se2--header-se--brand-selector .brand-list[data-desktop-size='l'] {
    width: 480px;
  }
  .se2--header-se--brand-selector .brand-list[data-desktop-size='l'] li {
    width: 25%;
  }
}

.se2--header-se--brand-selector .brand-list li {
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid var(--color-footer-gray);
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--brand-selector .brand-list li {
    border-right: 1px solid var(--color-footer-gray);
  }
  [dir="rtl"] .se2--header-se--brand-selector .brand-list li {
    border-left: 1px solid var(--color-footer-gray);
  }
  .se2--header-se--brand-selector .brand-list li {
    display: list-item;
    flex-grow: 0;
    justify-content: unset;
    align-items: unset;
    height: 70px;
    border-top: unset;
    border-bottom: 1px solid var(--color-footer-gray);
  }
}

[dir="ltr"] .se2--header-se--brand-selector .brand-list li::after {
  left: 0;
}

[dir="rtl"] .se2--header-se--brand-selector .brand-list li::after {
  right: 0;
}

[dir="ltr"] .se2--header-se--brand-selector .brand-list li::after {
  right: 0;
}

[dir="rtl"] .se2--header-se--brand-selector .brand-list li::after {
  left: 0;
}

.se2--header-se--brand-selector .brand-list li::after {
  position: absolute;
  top: 0;
  bottom: 0;
  content: '';
  display: none;
  height: 100%;
  width: 3px;
  background: var(--color-life-green);
}

@media (min-width: 1200px) {
  .se2--header-se--brand-selector .brand-list li::after {
    height: 3px;
    width: 100%;
  }
}

.se2--header-se--brand-selector .brand-list li:hover::after, .se2--header-se--brand-selector .brand-list li.selected::after {
  display: block;
}

[dir="ltr"] .se2--header-se--brand-selector .brand-list li:hover .visuallyhidden {
  left: 50%;
}

[dir="rtl"] .se2--header-se--brand-selector .brand-list li:hover .visuallyhidden {
  right: 50%;
}

.se2--header-se--brand-selector .brand-list li:hover .visuallyhidden {
  top: 50%;
  z-index: 60;
  height: auto;
  width: auto;
  border: 1px solid var(--color-black);
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap;
  text-transform: none;
  color: var(--color-black);
  background-color: var(--color-white);
  clip: auto;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--brand-selector .brand-list li:hover .visuallyhidden {
    left: 40px;
  }
  [dir="rtl"] .se2--header-se--brand-selector .brand-list li:hover .visuallyhidden {
    right: 40px;
  }
  .se2--header-se--brand-selector .brand-list li:hover .visuallyhidden {
    top: 40px;
  }
}

.se2--header-se--brand-selector .brand-list li a {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 1200px) {
  .se2--header-se--brand-selector .brand-list li a {
    justify-content: center;
    padding: 10px;
  }
  [dir="ltr"] .se2--header-se--brand-selector .brand-list li a:last-child {
    padding-right: 10px;
  }
  [dir="rtl"] .se2--header-se--brand-selector .brand-list li a:last-child {
    padding-left: 10px;
  }
}

.se2--header-se--brand-selector .brand-list li a .sdl-media-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (min-width: 1200px) {
  .se2--header-se--brand-selector .brand-list li a .sdl-media-wrap {
    text-align: center;
  }
}

.se2--header-se--brand-selector .brand-list li a .sdl-media-wrap img {
  max-height: 20px;
  min-width: unset;
}

@media (min-width: 1200px) {
  .se2--header-se--brand-selector .brand-list li a .sdl-media-wrap img {
    height: auto;
    max-height: 20px;
    max-width: 100%;
  }
}

.se2--header-se--brand-selector .brand-list li:hover::after, .se2--header-se--brand-selector .brand-list li.selected::after {
  display: block;
}

/* app/components/HeaderSE/partials/commonPart/index.scss */
/* app/stylesheets/mixins/stretchToFullWidth.scss */
/**
 * Mixin stretches element to whole available width of the container
 *
 *  @param {number} $block-start
 *  @param {number} $inline-end
 *  @param {number} $block-end
 *  @param {number} $inline-start
 *  @param {string} $position
 *
 *  Example:
 *    @include stretch-to-full-width();
 *    @include stretch-to-full-width($position: static, $block-start: unset);
 *    @include stretch-to-full-width($block-start: 100px, $inline-end: 200px, $block-end: 30px, $inline-start: 60px);
 *
 */
.se2--header-se--common-part {
  --logo-max-inline-size: 272px;
  --logo-brand-max-block-size: 44px;
  --page-content-sorrounding-gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 2px 0 rgba(51, 51, 51, 0.05), 0 2px 10px 0 rgba(51, 51, 51, 0.15);
  background: var(--color-white);
  transition: background-color 0.5s ease-out;
  pointer-events: auto;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part {
    --page-content-sorrounding-gap: 40px;
  }
}

.branded-header .se2--header-se--common-part {
  --logo-max-inline-size: 188px;
}

@media (min-width: 1200px) {
  html[dir="ltr"] .se2--header-se--common-part {
    padding-left: var(--page-content-sorrounding-gap);
  }
  html[dir="rtl"] .se2--header-se--common-part {
    padding-right: var(--page-content-sorrounding-gap);
  }
  html[dir="ltr"] .se2--header-se--common-part {
    padding-right: var(--page-content-sorrounding-gap);
  }
  html[dir="rtl"] .se2--header-se--common-part {
    padding-left: var(--page-content-sorrounding-gap);
  }
}

@media (min-width: 1200px) {
  .se2--header-se--common-part {
    flex-direction: row;
  }
  .transparent .se2--header-se--common-part {
    box-shadow: unset;
    background: linear-gradient(180deg, var(--color-black) 40%, rgba(171, 171, 171, 0) 100%);
  }
  .se2-accessibility .transparent .se2--header-se--common-part {
    box-shadow: 0 0 2px 0 rgba(51, 51, 51, 0.05), 0 2px 10px 0 rgba(51, 51, 51, 0.15);
    background: var(--color-white);
  }
  .reduced.transparent .se2--header-se--common-part {
    box-shadow: 0 0 2px 0 rgba(51, 51, 51, 0.05), 0 2px 10px 0 rgba(51, 51, 51, 0.15);
    background: var(--color-white);
  }
  .se2--header-se--common-part.with-right-side-logo {
    --tab-additional-horizontal-offset: 237px;
    justify-content: start;
  }
  .se2--header-se--common-part.with-right-side-logo .search-menu-wrapper {
    width: 65%;
  }
  [dir="ltr"] .se2--header-se--common-part.with-right-side-logo .sustainability-logo-link {
    margin-left: 50px;
  }
  [dir="rtl"] .se2--header-se--common-part.with-right-side-logo .sustainability-logo-link {
    margin-right: 50px;
  }
  .se2--header-se--common-part.with-right-side-logo .sustainability-logo-link {
    display: block;
    max-height: 60px;
    margin-top: 16px;
  }
}

@media only screen and (min-width: 1200px) and (min-width: 1200px) and (max-width: 1360px) {
  [dir="ltr"] .se2--header-se--common-part.with-right-side-logo .sustainability-logo-link {
    margin-left: 25px;
  }
  [dir="rtl"] .se2--header-se--common-part.with-right-side-logo .sustainability-logo-link {
    margin-right: 25px;
  }
}

@media (min-width: 1200px) {
  .se2--header-se--common-part.with-right-side-logo .sustainability-logo-link.simplified {
    align-self: center;
    margin-top: 0;
  }
  .se2--header-se--common-part.with-right-side-logo .sustainability-logo-link img {
    height: 54px;
    width: 177px;
    transition: filter 0.5s;
  }
}

@media only screen and (min-width: 1200px) and (min-width: 1200px) and (max-width: 1360px) {
  .se2--header-se--common-part.with-right-side-logo .sustainability-logo-link img {
    height: 35px;
    width: 118px;
  }
}

@media (min-width: 1200px) {
  .transparent:not(.reduced) .se2--header-se--common-part.with-right-side-logo .sustainability-logo-link.inverted-logo img {
    filter: invert(1) brightness(2);
  }
  .se2-accessibility .transparent:not(.reduced) .se2--header-se--common-part.with-right-side-logo .sustainability-logo-link.inverted-logo img {
    filter: invert(0);
  }
  .se2--header-se--common-part.with-right-side-logo .mm-tab {
    width: calc(100% + var(--tab-horizontal-offset) + var(--tab-additional-horizontal-offset));
  }
}

.se2--header-se--common-part .mm-l1-button-popup-flexible {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.se2--header-se--common-part .logo-se-link {
  display: flex;
}

.se2--header-se--common-part .logo-se-link.disabled {
  cursor: default;
  pointer-events: none;
}

[dir="ltr"] .se2--header-se--common-part .logo-se-link.branding {
  left: 15px;
}

[dir="rtl"] .se2--header-se--common-part .logo-se-link.branding {
  right: 15px;
}

.se2--header-se--common-part .logo-se-link.branding {
  position: absolute;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .logo-se-link.branding {
    top: -40px;
    z-index: 1;
  }
}

.se2--header-se--common-part .logo-se-link .logo-standalone {
  max-height: 60px;
  padding: 16px;
  background: var(--color-life-green);
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .logo-se-link .logo-standalone {
    max-height: 90px;
    padding: 18px;
  }
  .se2--header-se--common-part .logo-se-link .logo-standalone:hover {
    opacity: 0.7;
  }
}

.se2--header-se--common-part .logo-se-link .logo-standalone img {
  height: auto;
  width: 134px;
  aspect-ratio: attr(width)/attr(height);
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .logo-se-link .logo-standalone img {
    width: 236px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1360px) {
  .se2--header-se--common-part .logo-se-link .logo-standalone img {
    width: 191px;
  }
}

.se2--header-se--common-part .logo-se-link .logo-se-brand {
  max-height: 28px;
  padding: 4px 8px;
  background: var(--color-life-green);
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .logo-se-link .logo-se-brand {
    max-height: 90px;
    padding: 8px 16px;
  }
  .se2--header-se--common-part .logo-se-link .logo-se-brand:hover {
    opacity: 0.7;
  }
}

.se2--header-se--common-part .logo-se-link .logo-se-brand img {
  height: auto;
  width: 64px;
  aspect-ratio: attr(width)/attr(height);
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .logo-se-link .logo-se-brand img {
    height: 32px;
    width: 156px;
  }
}

[dir="ltr"] .se2--header-se--common-part .logo-brand-link {
  left: 0;
}

[dir="rtl"] .se2--header-se--common-part .logo-brand-link {
  right: 0;
}

.se2--header-se--common-part .logo-brand-link {
  position: absolute;
  top: 28px;
}

[dir="ltr"] .branded-header .se2--header-se--common-part .logo-brand-link {
  left: 15px;
}

[dir="rtl"] .branded-header .se2--header-se--common-part .logo-brand-link {
  right: 15px;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .logo-brand-link {
    top: -16px;
    display: flex;
    justify-content: center;
    width: var(--logo-max-inline-size);
    background: var(--color-white);
  }
}

.se2--header-se--common-part .logo-brand {
  max-height: 60px;
  padding: 6px 0;
}

.se2--header-se--common-part .logo-brand .sdl-media-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: var(--logo-brand-max-block-size);
}

.se2--header-se--common-part .logo-brand.logo-apc {
  padding-bottom: 0;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .logo-brand.logo-apc {
    padding-bottom: 12px;
  }
}

.se2--header-se--common-part .logo-brand.logo-apc img {
  max-height: calc(var(--logo-brand-max-block-size) / 2);
  aspect-ratio: attr(width)/attr(height);
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .logo-brand.logo-apc img {
    max-height: var(--logo-brand-max-block-size);
  }
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .logo-brand {
    max-height: 90px;
    padding: 40px 0 12px;
  }
  .se2--header-se--common-part .logo-brand:hover {
    opacity: 0.7;
  }
}

.se2--header-se--common-part .logo-brand img {
  height: auto;
  max-height: calc(var(--logo-brand-max-block-size) / 2);
  width: 80px;
  aspect-ratio: attr(width)/attr(height);
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .logo-brand img {
    max-height: var(--logo-brand-max-block-size);
    width: 140px;
  }
}

.se2--header-se--common-part .mobile-block-wrap {
  display: flex;
  justify-content: space-between;
  height: 60px;
}

.branded-header .se2--header-se--common-part .mobile-block-wrap {
  justify-content: flex-end;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mobile-block-wrap {
    position: relative;
    display: block;
    height: auto;
    min-width: var(--logo-max-inline-size);
  }
}

[dir="ltr"] .se2--header-se--common-part .mobile-block {
  margin-right: 12px;
}

[dir="rtl"] .se2--header-se--common-part .mobile-block {
  margin-left: 12px;
}

.se2--header-se--common-part .mobile-block {
  display: flex;
  align-items: center;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mobile-block {
    display: none;
  }
}

.se2--header-se--common-part .mobile-block .btn-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border: 0;
  padding: 0;
  color: var(--color-text-gray);
  background: transparent;
  opacity: 0.7;
  cursor: pointer;
}

.se2--header-se--common-part .mobile-block .btn-nav [data-count='0'] .cart-count {
  display: none;
}

[dir="ltr"] .se2--header-se--common-part .mobile-block .btn-nav + .btn-nav {
  margin-left: 4px;
}

[dir="rtl"] .se2--header-se--common-part .mobile-block .btn-nav + .btn-nav {
  margin-right: 4px;
}

.se2--header-se--common-part .search-menu-wrapper {
  width: 100%;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--common-part .search-menu-wrapper {
    margin-left: 30px;
  }
  [dir="rtl"] .se2--header-se--common-part .search-menu-wrapper {
    margin-right: 30px;
  }
  .se2--header-se--common-part .search-menu-wrapper {
    margin-top: 12px;
  }
  .se2--header-se--common-part .search-menu-wrapper.menu-wrapper-simplified {
    margin-top: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1360px) {
  .se2--header-se--common-part .search-menu-wrapper {
    min-width: inherit;
  }
}

.se2--header-se--common-part .sustainability-logo-link {
  display: none;
}

.se2--header-se--common-part .menu-wrapper-apc {
  position: absolute;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .menu-wrapper-apc {
    position: static;
  }
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .menu-wrapper-simplified .mm-wrap .mm-nav,
  .se2--header-se--common-part .menu-wrapper-simplified .mm-wrap .mm-l1-list {
    height: 100%;
  }
}

[dir="ltr"] .se2--header-se--common-part .mm-wrap {
  left: 0;
}

[dir="rtl"] .se2--header-se--common-part .mm-wrap {
  right: 0;
}

[dir="ltr"] .se2--header-se--common-part .mm-wrap {
  right: 0;
}

[dir="rtl"] .se2--header-se--common-part .mm-wrap {
  left: 0;
}

.se2--header-se--common-part .mm-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  height: var(--window-height-inner);
  overflow-y: scroll;
  background-color: var(--color-white);
}

.se2--header-se--common-part .mm-wrap .mm-nav {
  margin-top: 60px;
}

.se2--header-se--common-part .mm-wrap .sustainability-logo-link {
  display: block;
  width: fit-content;
  padding-top: 10px;
  padding-bottom: 10px;
}

.se2--header-se--common-part .mm-wrap .sustainability-logo-link img {
  height: 50px;
  width: 160px;
  min-width: initial;
}

.se2--header-se--common-part .mm-wrap.close {
  display: none;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-wrap {
    position: initial;
    display: initial;
    height: 100%;
  }
  .se2--header-se--common-part .mm-wrap .mob-b2bB2cToggle {
    display: none;
  }
  .se2--header-se--common-part .mm-wrap.close {
    display: initial;
  }
  .se2--header-se--common-part .mm-wrap .mm-nav {
    margin-top: 0;
  }
}

.se2--header-se--common-part .mm-wrap.open .mm-l1-list {
  border-top: 0;
}

.se2--header-se--common-part .mm-wrap.open .mm-nav {
  margin-top: 0;
}

.se2--header-se--common-part .mm-wrap.open .mm-l1-item:not(.open):not(.close) {
  display: none;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-wrap.open .mm-l1-item:not(.open):not(.close) {
    display: block;
  }
}

[dir="ltr"] .se2--header-se--common-part .mobile-menu-btn-close {
  right: 0;
}

[dir="rtl"] .se2--header-se--common-part .mobile-menu-btn-close {
  left: 0;
}

[dir="ltr"] .se2--header-se--common-part .mobile-menu-btn-close {
  padding-left: 16px;
}

[dir="rtl"] .se2--header-se--common-part .mobile-menu-btn-close {
  padding-right: 16px;
}

[dir="ltr"] .se2--header-se--common-part .mobile-menu-btn-close {
  padding-right: 24px;
}

[dir="rtl"] .se2--header-se--common-part .mobile-menu-btn-close {
  padding-left: 24px;
}

.se2--header-se--common-part .mobile-menu-btn-close {
  position: absolute;
  top: 0;
  z-index: 10;
  border: 0;
  padding-top: 22px;
  padding-bottom: 22px;
  background: none;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mobile-menu-btn-close {
    display: none;
  }
}

.se2--header-se--common-part .mobile-menu-btn-close.se2-icon-close::before {
  font-size: 120%;
  color: var(--color-light-gray);
}

.se2--header-se--common-part .mm-l1-list {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--color-footer-gray);
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l1-list {
    position: relative;
    flex-direction: row;
    border-top: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.se2--header-se--common-part .mm-l1-item-link {
  text-decoration: none;
}

.se2--header-se--common-part .mm-l1-item.close {
  display: none;
}

.se2--header-se--common-part .mm-l1-item.open {
  display: block;
}

.se2--header-se--common-part .mm-l1-item.open .mm-l1-section-back-chevron {
  display: block;
}

.se2--header-se--common-part .mm-l1-item.open .mm-l1-section-name-chevron {
  display: none;
}

.se2--header-se--common-part .mm-l1-item.open .mm-l1-section-name {
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

[dir="ltr"] .se2--header-se--common-part .mm-l1-item.open .mm-l1-button-popup {
  padding-left: 20px;
}

[dir="rtl"] .se2--header-se--common-part .mm-l1-item.open .mm-l1-button-popup {
  padding-right: 20px;
}

.se2--header-se--common-part .mm-l1-item.open .mm-l1-button-popup {
  display: grid;
  grid-template-columns: 10% 78% 12%;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l1-item.open .mm-l1-button-popup {
    display: flex;
    justify-content: flex-start;
    justify-content: inherit;
  }
}

.se2--header-se--common-part .mm-l1-item.open .mm-l1-button-popup.close {
  display: none;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l1-item.open .mm-l1-button-popup.close {
    display: block;
  }
}

.se2--header-se--common-part .mm-l1-item.open .mm-l1-button-popup span {
  display: flex;
  justify-content: flex-end;
}

.se2--header-se--common-part .mm-l1-item.open .mm-l1-button-popup .mm-l1-section-name-chevron {
  display: none;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l1-item.open .mm-l1-button-popup .mm-l1-section-name-chevron {
    display: block;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l1-item.open .mm-l1-button-popup .mm-l1-section-name-chevron.se2-icon-arrow-common::after {
    transform: rotate(270deg);
  }
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l1-item.open .mm-l1-button-popup .mm-l1-section-back-chevron {
    display: none;
  }
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--common-part .mm-l1-item {
    margin-right: 36px;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l1-item {
    margin-left: 36px;
  }
  .se2--header-se--common-part .mm-l1-item.close {
    display: block;
  }
  [dir="ltr"] .se2--header-se--common-part .mm-l1-item.open .mm-l1-button-popup {
    padding-left: 0;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l1-item.open .mm-l1-button-popup {
    padding-right: 0;
  }
  .se2--header-se--common-part .mm-l1-item.open .mm-l1-button-popup::after {
    opacity: 1;
  }
  .se2--header-se--common-part .mm-l1-item.open .mm-l1-section-back-chevron {
    display: none;
  }
  .se2--header-se--common-part .mm-l1-item.open .mm-l1-section-name-chevron {
    display: block;
  }
  .se2--header-se--common-part .mm-l1-item.open .mm-l1-section-name-chevron.se2-icon-arrow-common::after {
    width: 20px;
    font-size: 120%;
    line-height: 20px;
    transform: rotate(-90deg);
  }
  [dir="ltr"] .se2--header-se--common-part .mm-l1-item:last-child {
    margin-right: 0;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l1-item:last-child {
    margin-left: 0;
  }
}

.se2--header-se--common-part .mm-l1-button-popup {
  height: 100%;
}

.se2--header-se--common-part .mm-l1-item-link {
  height: auto;
}

[dir="ltr"] .se2--header-se--common-part .mm-l1-button-popup,[dir="ltr"] 
.se2--header-se--common-part .mm-l1-item-link {
  padding-left: 30px;
}

[dir="rtl"] .se2--header-se--common-part .mm-l1-button-popup,[dir="rtl"] 
.se2--header-se--common-part .mm-l1-item-link {
  padding-right: 30px;
}

[dir="ltr"] .se2--header-se--common-part .mm-l1-button-popup,[dir="ltr"] 
.se2--header-se--common-part .mm-l1-item-link {
  padding-right: 12px;
}

[dir="rtl"] .se2--header-se--common-part .mm-l1-button-popup,[dir="rtl"] 
.se2--header-se--common-part .mm-l1-item-link {
  padding-left: 12px;
}

.se2--header-se--common-part .mm-l1-button-popup,
.se2--header-se--common-part .mm-l1-item-link {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border: 0;
  border-bottom: 1px solid var(--color-footer-gray);
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  background: transparent;
  cursor: pointer;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--common-part .mm-l1-button-popup,[dir="ltr"] 
  .se2--header-se--common-part .mm-l1-item-link {
    padding-left: 0;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l1-button-popup,[dir="rtl"] 
  .se2--header-se--common-part .mm-l1-item-link {
    padding-right: 0;
  }
  [dir="ltr"] .se2--header-se--common-part .mm-l1-button-popup,[dir="ltr"] 
  .se2--header-se--common-part .mm-l1-item-link {
    padding-right: 0;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l1-button-popup,[dir="rtl"] 
  .se2--header-se--common-part .mm-l1-item-link {
    padding-left: 0;
  }
  .se2--header-se--common-part .mm-l1-button-popup,
  .se2--header-se--common-part .mm-l1-item-link {
    border-bottom: 0;
    padding: 0;
    text-transform: none;
  }
  [dir="ltr"] .se2--header-se--common-part .mm-l1-button-popup::after,[dir="ltr"] 
  .se2--header-se--common-part .mm-l1-item-link::after {
    left: 0;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l1-button-popup::after,[dir="rtl"] 
  .se2--header-se--common-part .mm-l1-item-link::after {
    right: 0;
  }
  [dir="ltr"] .se2--header-se--common-part .mm-l1-button-popup::after,[dir="ltr"] 
  .se2--header-se--common-part .mm-l1-item-link::after {
    right: 0;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l1-button-popup::after,[dir="rtl"] 
  .se2--header-se--common-part .mm-l1-item-link::after {
    left: 0;
  }
  .se2--header-se--common-part .mm-l1-button-popup::after,
  .se2--header-se--common-part .mm-l1-item-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    z-index: 20;
    border-bottom: 4px solid var(--color-life-green);
    opacity: 0;
    transition: opacity linear 0.3s;
  }
  .se2--header-se--common-part .mm-l1-button-popup:hover::after,
  .se2--header-se--common-part .mm-l1-item-link:hover::after {
    opacity: 1;
  }
}

.se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-back-chevron,
.se2--header-se--common-part .mm-l1-item-link .mm-l1-section-back-chevron {
  display: none;
  transform: rotate(-180deg);
}

.se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-back-chevron.se2-icon-arrow-common::after,
.se2--header-se--common-part .mm-l1-item-link .mm-l1-section-back-chevron.se2-icon-arrow-common::after {
  width: 22px;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-back-chevron,
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-back-chevron {
    display: none;
  }
}

.se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-name-chevron.se2-icon-arrow-common::after,
.se2--header-se--common-part .mm-l1-item-link .mm-l1-section-name-chevron.se2-icon-arrow-common::after {
  width: 40px;
}

.se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-name-chevron,
.se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-back-chevron,
.se2--header-se--common-part .mm-l1-item-link .mm-l1-section-name-chevron,
.se2--header-se--common-part .mm-l1-item-link .mm-l1-section-back-chevron {
  color: var(--color-light-gray);
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-name-chevron,[dir="ltr"] 
  .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-back-chevron,[dir="ltr"] 
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-name-chevron,[dir="ltr"] 
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-back-chevron {
    margin-right: 0;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-name-chevron,[dir="rtl"] 
  .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-back-chevron,[dir="rtl"] 
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-name-chevron,[dir="rtl"] 
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-back-chevron {
    margin-left: 0;
  }
  .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-name-chevron,
  .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-back-chevron,
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-name-chevron,
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-back-chevron {
    height: 20px;
    width: 20px;
  }
}

.se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-name-chevron.se2-icon-arrow-common::after,
.se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-back-chevron.se2-icon-arrow-common::after,
.se2--header-se--common-part .mm-l1-item-link .mm-l1-section-name-chevron.se2-icon-arrow-common::after,
.se2--header-se--common-part .mm-l1-item-link .mm-l1-section-back-chevron.se2-icon-arrow-common::after {
  font-size: 170%;
  line-height: 14px;
}

[dir="rtl"] .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-name-chevron.se2-icon-arrow-common::after,[dir="rtl"] 
.se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-back-chevron.se2-icon-arrow-common::after,[dir="rtl"] 
.se2--header-se--common-part .mm-l1-item-link .mm-l1-section-name-chevron.se2-icon-arrow-common::after,[dir="rtl"] 
.se2--header-se--common-part .mm-l1-item-link .mm-l1-section-back-chevron.se2-icon-arrow-common::after {
  transform: rotate(180deg);
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-name-chevron.se2-icon-arrow-common::after,
  .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-back-chevron.se2-icon-arrow-common::after,
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-name-chevron.se2-icon-arrow-common::after,
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-back-chevron.se2-icon-arrow-common::after {
    width: 20px;
    font-size: 120%;
    line-height: 20px;
    transform: rotate(90deg);
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-name-chevron.se2-icon-arrow-common::after,[dir="rtl"] 
  .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-back-chevron.se2-icon-arrow-common::after,[dir="rtl"] 
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-name-chevron.se2-icon-arrow-common::after,[dir="rtl"] 
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-back-chevron.se2-icon-arrow-common::after {
    transform: rotate(90deg);
  }
  .transparent .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-name-chevron.se2-icon-arrow-common::after, .transparent
  .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-back-chevron.se2-icon-arrow-common::after, .transparent
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-name-chevron.se2-icon-arrow-common::after, .transparent
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-back-chevron.se2-icon-arrow-common::after {
    color: var(--color-white);
  }
  .se2-accessibility .transparent .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-name-chevron.se2-icon-arrow-common::after, .se2-accessibility .transparent
  .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-back-chevron.se2-icon-arrow-common::after, .se2-accessibility .transparent
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-name-chevron.se2-icon-arrow-common::after, .se2-accessibility .transparent
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-back-chevron.se2-icon-arrow-common::after {
    color: var(--color-light-gray);
  }
  .reduced.transparent .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-name-chevron.se2-icon-arrow-common::after, .reduced.transparent
  .se2--header-se--common-part .mm-l1-button-popup .mm-l1-section-back-chevron.se2-icon-arrow-common::after, .reduced.transparent
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-name-chevron.se2-icon-arrow-common::after, .reduced.transparent
  .se2--header-se--common-part .mm-l1-item-link .mm-l1-section-back-chevron.se2-icon-arrow-common::after {
    color: var(--color-light-gray);
  }
}

.se2--header-se--common-part .mm-l1-button-popup.close,
.se2--header-se--common-part .mm-l1-item-link.close {
  display: none;
}

.se2--header-se--common-part .mm-l1-section-name {
  position: relative;
  display: flex;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: var(--color-text-gray);
  transition: font-size 0.25s linear;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--common-part .mm-l1-section-name {
    margin-right: 0;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l1-section-name {
    margin-left: 0;
  }
  .se2--header-se--common-part .mm-l1-section-name {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .transparent .se2--header-se--common-part .mm-l1-section-name {
    color: var(--color-white);
  }
  .se2-accessibility .transparent .se2--header-se--common-part .mm-l1-section-name {
    color: var(--color-black);
  }
  .header-brand-simplified .se2--header-se--common-part .mm-l1-section-name {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .reduced.transparent .se2--header-se--common-part .mm-l1-section-name {
    color: var(--color-black);
  }
}

[dir="ltr"] .se2--header-se--common-part .mm-l1-item.open .mm-l1-section-name {
  margin-right: 36px;
}

[dir="rtl"] .se2--header-se--common-part .mm-l1-item.open .mm-l1-section-name {
  margin-left: 36px;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--common-part .mm-l1-item.open .mm-l1-section-name {
    margin-right: 0;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l1-item.open .mm-l1-section-name {
    margin-left: 0;
  }
}

.se2--header-se--common-part .mm-tab.stubbed-content {
  min-height: 90px;
}

.se2--header-se--common-part .mm-tab.close {
  display: none;
}

.se2--header-se--common-part .mm-tab .white-background {
  background-color: var(--color-white);
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--common-part .mm-tab {
    left: calc(-1 * var(--tab-horizontal-offset));
  }
  [dir="rtl"] .se2--header-se--common-part .mm-tab {
    right: calc(-1 * var(--tab-horizontal-offset));
  }
  [dir="ltr"] .se2--header-se--common-part .mm-tab {
    border-bottom-left-radius: 2px;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-tab {
    border-bottom-right-radius: 2px;
  }
  [dir="ltr"] .se2--header-se--common-part .mm-tab {
    border-bottom-right-radius: 2px;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-tab {
    border-bottom-left-radius: 2px;
  }
  .se2--header-se--common-part .mm-tab {
    position: absolute;
    top: var(--tab-vertical-offset);
    max-height: calc(100vh - var(--header-height-possible-max));
    width: calc(100% + var(--tab-horizontal-offset));
    overflow-y: auto;
    box-shadow: 0 0 2px 0 rgba(51, 51, 51, 0.5), 0 7px 15px 0 rgba(51, 51, 51, 0.5);
    background: var(--color-bg-gray);
  }
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--common-part .mm-main-list-wrap {
    padding-left: 40px;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-main-list-wrap {
    padding-right: 40px;
  }
  [dir="ltr"] .se2--header-se--common-part .mm-main-list-wrap {
    padding-right: 40px;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-main-list-wrap {
    padding-left: 40px;
  }
  .se2--header-se--common-part .mm-main-list-wrap {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.se2--header-se--common-part .mm-cta-wrap {
  display: flex;
  flex-direction: column;
}

.se2--header-se--common-part .mm-cta-wrap.close {
  display: none;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-cta-wrap {
    flex-direction: row;
    margin-bottom: 15px;
  }
}

[dir="ltr"] .se2--header-se--common-part .mm-see-all-title {
  padding-left: 20px;
}

[dir="rtl"] .se2--header-se--common-part .mm-see-all-title {
  padding-right: 20px;
}

[dir="ltr"] .se2--header-se--common-part .mm-see-all-title {
  padding-right: 15px;
}

[dir="rtl"] .se2--header-se--common-part .mm-see-all-title {
  padding-left: 15px;
}

.se2--header-se--common-part .mm-see-all-title {
  width: 100%;
  border-bottom: 1px solid var(--color-footer-gray);
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 700;
}

.se2--header-se--common-part .mm-see-all-title svg {
  height: 15px;
  width: 15px;
}

[dir="rtl"] .se2--header-se--common-part .mm-see-all-title svg {
  transform: rotate(180deg);
}

[dir="rtl"] .se2--header-se--common-part .mm-see-all-title svg.openInNewWindow {
  transform: rotate(270deg);
}

.se2--header-se--common-part .mm-see-all-title-wrapper {
  display: flex;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--common-part .mm-see-all-title {
    padding-left: 10px;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-see-all-title {
    padding-right: 10px;
  }
  [dir="ltr"] .se2--header-se--common-part .mm-see-all-title {
    padding-right: 10px;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-see-all-title {
    padding-left: 10px;
  }
  .se2--header-se--common-part .mm-see-all-title {
    width: fit-content;
    border-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .se2--header-se--common-part .mm-see-all-title svg {
    display: initial;
    height: 25px;
    width: 25px;
  }
  .se2--header-se--common-part .mm-see-all-title-wrapper {
    width: 25%;
  }
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-cta {
    min-width: 50%;
    max-width: 75%;
  }
}

[dir="ltr"] .se2--header-se--common-part .mm-cta .mm-cta-link {
  padding-left: 40px;
}

[dir="rtl"] .se2--header-se--common-part .mm-cta .mm-cta-link {
  padding-right: 40px;
}

[dir="ltr"] .se2--header-se--common-part .mm-cta .mm-cta-link {
  padding-right: 40px;
}

[dir="rtl"] .se2--header-se--common-part .mm-cta .mm-cta-link {
  padding-left: 40px;
}

.se2--header-se--common-part .mm-cta .mm-cta-link {
  position: relative;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--color-footer-gray);
  padding-top: 5px;
  padding-bottom: 5px;
  text-decoration: none;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-cta .mm-cta-link {
    border: 1px solid var(--color-footer-gray);
  }
}

.se2--header-se--common-part .mm-cta .mm-cta-link:hover p {
  text-decoration: underline;
}

.se2--header-se--common-part .mm-cta .mm-cta-link:hover p span .nowrap {
  text-decoration: underline;
}

.se2--header-se--common-part .mm-cta .mm-cta-content-title {
  color: var(--color-dark-gray);
}

.se2--header-se--common-part .mm-cta .mm-cta-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

[dir="ltr"] .se2--header-se--common-part .mm-cta .mm-cta-icon {
  left: 0;
}

[dir="rtl"] .se2--header-se--common-part .mm-cta .mm-cta-icon {
  right: 0;
}

[dir="ltr"] .se2--header-se--common-part .mm-cta .mm-cta-icon {
  right: 0;
}

[dir="rtl"] .se2--header-se--common-part .mm-cta .mm-cta-icon {
  left: 0;
}

[dir="ltr"] .se2--header-se--common-part .mm-cta .mm-cta-icon {
  border-bottom-right-radius: 100%;
}

[dir="rtl"] .se2--header-se--common-part .mm-cta .mm-cta-icon {
  border-bottom-left-radius: 100%;
}

.se2--header-se--common-part .mm-cta .mm-cta-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background: var(--color-sky-blue);
}

[dir="ltr"] .se2--header-se--common-part .mm-cta .mm-cta-icon.se2-icon-doc::before {
  left: 5px;
}

[dir="rtl"] .se2--header-se--common-part .mm-cta .mm-cta-icon.se2-icon-doc::before {
  right: 5px;
}

.se2--header-se--common-part .mm-cta .mm-cta-icon.se2-icon-doc::before {
  position: absolute;
  top: 5px;
  color: var(--color-white);
}

.se2--header-se--common-part .mm-l2-list--top {
  display: none;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l2-list--top {
    display: flex;
    margin-bottom: 15px;
  }
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l2-list {
    column-count: 4;
    column-gap: 0;
  }
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l2-list-column {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    overflow-y: auto;
  }
}

[dir="ltr"] .se2--header-se--common-part .mm-l2-button {
  padding-left: 20px;
}

[dir="rtl"] .se2--header-se--common-part .mm-l2-button {
  padding-right: 20px;
}

[dir="ltr"] .se2--header-se--common-part .mm-l2-button {
  padding-right: 24px;
}

[dir="rtl"] .se2--header-se--common-part .mm-l2-button {
  padding-left: 24px;
}

.se2--header-se--common-part .mm-l2-button {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  border: 0;
  border-bottom: 1px solid var(--color-footer-gray);
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: var(--color-text-gray);
  background: transparent;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l2-button {
    display: none;
  }
}

.se2--header-se--common-part .mm-l2-button .mm-l2-section-back-chevron {
  display: none;
  transform: rotate(-180deg);
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l2-button .mm-l2-section-back-chevron {
    display: none;
  }
}

.se2--header-se--common-part .mm-l2-button .mm-l2-section-back-chevron.se2-icon-arrow-common::after {
  width: 22px;
  font-size: 220%;
  line-height: 14px;
  color: var(--color-light-gray);
}

[dir="rtl"] .se2--header-se--common-part .mm-l2-button .mm-l2-section-back-chevron.se2-icon-arrow-common::after {
  transform: rotate(0deg);
}

.se2--header-se--common-part .mm-l2-button .mm-l2-section-name-chevron.se2-icon-arrow-common::after {
  font-size: 150%;
  line-height: 10px;
}

.se2--header-se--common-part .mm-l2-button .mm-l2-section-name-chevron.se2-icon-arrow-common::after,
.se2--header-se--common-part .mm-l2-button .mm-l2-section-back-chevron.se2-icon-arrow-common::after {
  color: var(--color-light-gray);
}

[dir="rtl"] .se2--header-se--common-part .mm-l2-button .mm-l2-section-name-chevron.se2-icon-arrow-common::after,[dir="rtl"] 
.se2--header-se--common-part .mm-l2-button .mm-l2-section-back-chevron.se2-icon-arrow-common::after {
  transform: rotate(180deg);
}

[dir="ltr"] .se2--header-se--common-part .mm-l2-section-name {
  text-align: left;
}

[dir="rtl"] .se2--header-se--common-part .mm-l2-section-name {
  text-align: right;
}

.se2--header-se--common-part .mm-l2-section-name {
  position: relative;
  display: flex;
  width: 100%;
  color: var(--color-text-gray);
  transition: font-size 0.25s linear;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l2-section-name {
    display: inline-block;
  }
}

[dir="ltr"] .se2--header-se--common-part .mm-l2-item.open .mm-l2-section-name {
  margin-right: 30px;
}

[dir="rtl"] .se2--header-se--common-part .mm-l2-item.open .mm-l2-section-name {
  margin-left: 30px;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--common-part .mm-l2-item.open .mm-l2-section-name {
    margin-right: 0;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l2-item.open .mm-l2-section-name {
    margin-left: 0;
  }
}

.se2--header-se--common-part .mm-l2-item.open .mm-l2-section-name {
  text-align: center;
}

.se2--header-se--common-part .mm-l2-item.open .mm-l2-button {
  display: grid;
  grid-template-columns: 10% 80% 10%;
  font-weight: 700;
  text-align: center;
}

.se2--header-se--common-part .mm-l2-item.open .mm-l2-button span {
  display: flex;
  justify-content: flex-end;
}

.se2--header-se--common-part .mm-l2-item.open .mm-l2-button .mm-l2-section-name-chevron {
  display: none;
}

.se2--header-se--common-part .mm-promo-img-wrap {
  display: none;
  width: 100%;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-promo-img-wrap {
    display: block;
  }
}

.se2--header-se--common-part .mm-promo-content-wrap {
  display: none;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-promo-content-wrap {
    display: block;
  }
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-promo {
    flex: 0 0 25%;
    height: 100%;
  }
  .se2--header-se--common-part .mm-promo-title {
    margin-bottom: 10px;
    font-weight: 700;
    color: var(--color-life-green);
  }
  .se2-accessibility .se2--header-se--common-part .mm-promo-title {
    color: var(--color-text-gray);
  }
  .se2--header-se--common-part .mm-promo-description {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 15px;
  }
  .se2--header-se--common-part .mm-promo-link:hover {
    text-decoration: underline;
  }
  .se2--header-se--common-part .mm-promo-link svg {
    height: 18px;
    width: 18px;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-promo-link svg {
    transform: rotate(180deg);
  }
  .se2--header-se--common-part .mm-promo img {
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-promo-cta:not(:last-child) {
    margin-bottom: 10px;
  }
}

.se2--header-se--common-part .mm-promo-cta.close {
  display: none;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-promo-cta.close {
    display: block;
  }
}

.se2--header-se--common-part .mm-promo-cta-link {
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid var(--color-footer-gray);
  padding: 15px 30px;
  text-decoration: none;
}

.se2--header-se--common-part .mm-promo-cta-link .mm-promo-cta-content-description {
  font-weight: normal;
}

.se2--header-se--common-part .mm-promo-cta-link:hover .mm-promo-cta-content-description {
  text-decoration: underline;
}

[dir="ltr"] .se2--header-se--common-part .mm-promo-cta-icon {
  left: 0;
}

[dir="rtl"] .se2--header-se--common-part .mm-promo-cta-icon {
  right: 0;
}

[dir="ltr"] .se2--header-se--common-part .mm-promo-cta-icon {
  right: 0;
}

[dir="rtl"] .se2--header-se--common-part .mm-promo-cta-icon {
  left: 0;
}

[dir="ltr"] .se2--header-se--common-part .mm-promo-cta-icon {
  border-bottom-right-radius: 100%;
}

[dir="rtl"] .se2--header-se--common-part .mm-promo-cta-icon {
  border-bottom-left-radius: 100%;
}

.se2--header-se--common-part .mm-promo-cta-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background: var(--color-sky-blue);
}

[dir="ltr"] .se2--header-se--common-part .mm-promo-cta-icon svg {
  margin-left: -5px;
}

[dir="rtl"] .se2--header-se--common-part .mm-promo-cta-icon svg {
  margin-right: -5px;
}

.se2--header-se--common-part .mm-promo-cta-icon svg {
  height: 15px;
  width: 15px;
  margin-top: -5px;
  color: var(--color-white);
}

[dir="rtl"] .se2--header-se--common-part .mm-promo-cta-icon svg {
  transform: rotate(180deg);
}

.se2--header-se--common-part .mm-promo-cta-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
}

[dir="ltr"] .se2--header-se--common-part .mm-promo-cta-content-title {
  margin-right: 0;
}

[dir="rtl"] .se2--header-se--common-part .mm-promo-cta-content-title {
  margin-left: 0;
}

.se2--header-se--common-part .mm-promo-cta-content-title {
  font-weight: 700;
  text-align: center;
  color: var(--color-dark-gray);
}

.se2--header-se--common-part .mm-promo-cta-content-description {
  margin-top: 5px;
}

.se2--header-se--common-part .mm-promo-cta-content-description svg {
  height: 18px;
  width: 18px;
}

[dir="rtl"] .se2--header-se--common-part .mm-promo-cta-content-description svg {
  transform: rotate(180deg);
}

.se2--header-se--common-part .mm-main-list,
.se2--header-se--common-part .mm-main-list-products {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-main-list .mm-see-all-title-wrapper .se2-icon-arrow-double::after,
  .se2--header-se--common-part .mm-main-list-products .mm-see-all-title-wrapper .se2-icon-arrow-double::after {
    font-size: 155%;
  }
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-main-list:not(:only-child) .mm-main-list {
    flex: 0 0 75%;
  }
  .se2--header-se--common-part .mm-main-list:not(:only-child) .mm-l2-list {
    column-count: 3;
  }
  .se2--header-se--common-part .mm-main-list:not(:only-child) .mm-see-all-title-wrapper {
    width: 33%;
  }
}

.se2--header-se--common-part .mm-l2-item.close {
  display: none;
}

.se2--header-se--common-part .mm-l2-item.open .mm-l2-section-name {
  justify-content: center;
  font-weight: 700;
}

[dir="ltr"] .se2--header-se--common-part .mm-l2-item.open .mm-l2-section-back-chevron {
  margin-right: 16px;
}

[dir="rtl"] .se2--header-se--common-part .mm-l2-item.open .mm-l2-section-back-chevron {
  margin-left: 16px;
}

.se2--header-se--common-part .mm-l2-item.open .mm-l2-section-back-chevron {
  display: block;
}

.se2--header-se--common-part .mm-l2-item.open .mm-l2-section-name-chevron {
  display: none;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l2-item-mobile {
    display: none;
  }
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l2-item {
    margin-bottom: 15px;
  }
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l2-item.extra-links {
    display: none;
  }
}

.se2--header-se--common-part .mm-l2-title {
  display: none;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--common-part .mm-l2-title {
    padding-left: 10px;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l2-title {
    padding-right: 10px;
  }
  [dir="ltr"] .se2--header-se--common-part .mm-l2-title {
    padding-right: 10px;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l2-title {
    padding-left: 10px;
  }
  .se2--header-se--common-part .mm-l2-title {
    display: initial;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-text-gray);
  }
}

[dir="ltr"] .se2--header-se--common-part .mm-l2-link {
  padding-left: 20px;
}

[dir="rtl"] .se2--header-se--common-part .mm-l2-link {
  padding-right: 20px;
}

[dir="ltr"] .se2--header-se--common-part .mm-l2-link {
  padding-right: 16px;
}

[dir="rtl"] .se2--header-se--common-part .mm-l2-link {
  padding-left: 16px;
}

.se2--header-se--common-part .mm-l2-link {
  display: block;
  border-bottom: 1px solid var(--color-footer-gray);
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l2-link .se2-icon-arrow-common::after {
    font-size: 145%;
  }
}

.se2--header-se--common-part .mm-l2-link svg {
  height: 20px;
  width: 20px;
}

[dir="rtl"] .se2--header-se--common-part .mm-l2-link svg {
  transform: rotate(180deg);
}

[dir="rtl"] .se2--header-se--common-part .mm-l2-link svg.openInNewWindow {
  transform: rotate(270deg);
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--common-part .mm-l2-link {
    padding-left: 10px;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l2-link {
    padding-right: 10px;
  }
  [dir="ltr"] .se2--header-se--common-part .mm-l2-link {
    padding-right: 10px;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l2-link {
    padding-left: 10px;
  }
  .se2--header-se--common-part .mm-l2-link {
    display: block;
    width: fit-content;
    border-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 5px;
    font-weight: 700;
  }
  .se2--header-se--common-part .mm-l2-link svg.arrow {
    display: initial;
    color: var(--color-text-gray);
  }
}

.se2--header-se--common-part .mm-l2-link.mm-l2-top-mobile-link {
  position: relative;
  background-color: var(--color-bg-gray-new);
}

.se2--header-se--common-part .mm-l2-link.mm-l2-top-mobile-link::after {
  content: '';
  position: absolute;
  top: 100%;
  border: 10px solid transparent;
  border-top: 10px solid var(--color-bg-gray-new);
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l2-link.mm-l2-top-mobile-link {
    display: none;
  }
}

.se2--header-se--common-part .mm-l3-list {
  display: flex;
  flex-direction: column;
}

.se2--header-se--common-part .mm-l3-list .mm-l2-link {
  display: block;
}

.se2--header-se--common-part .mm-l3-list .mm-l2-link .se2-icon-arrow-common::after {
  font-size: 143%;
  color: var(--color-light-gray);
}

.se2--header-se--common-part .mm-l3-list.close {
  display: none;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l3-list {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    overflow-y: hidden;
  }
  .se2--header-se--common-part .mm-l3-list .mm-l2-link .se2-icon-arrow-common::after {
    color: inherit;
  }
  .se2--header-se--common-part .mm-l3-list.close {
    display: flex;
  }
}

[dir="ltr"] .se2--header-se--common-part .mm-l3-link {
  padding-left: 20px;
}

[dir="rtl"] .se2--header-se--common-part .mm-l3-link {
  padding-right: 20px;
}

[dir="ltr"] .se2--header-se--common-part .mm-l3-link {
  padding-right: 15px;
}

[dir="rtl"] .se2--header-se--common-part .mm-l3-link {
  padding-left: 15px;
}

.se2--header-se--common-part .mm-l3-link {
  border-bottom: 1px solid var(--color-footer-gray);
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-l3-link .se2-icon-new-tab::after {
    font-size: 150%;
  }
}

.se2--header-se--common-part .mm-l3-link:last-child {
  border: none;
}

.se2--header-se--common-part .mm-l3-link svg {
  display: initial;
  height: 18px;
  width: 18px;
}

[dir="rtl"] .se2--header-se--common-part .mm-l3-link svg {
  transform: rotate(180deg);
}

[dir="rtl"] .se2--header-se--common-part .mm-l3-link svg.openInNewWindow {
  transform: rotate(270deg);
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--common-part .mm-l3-link {
    padding-left: 10px;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l3-link {
    padding-right: 10px;
  }
  [dir="ltr"] .se2--header-se--common-part .mm-l3-link {
    padding-right: 10px;
  }
  [dir="rtl"] .se2--header-se--common-part .mm-l3-link {
    padding-left: 10px;
  }
  .se2--header-se--common-part .mm-l3-link {
    width: fit-content;
    border-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
    line-height: 18px;
  }
  .se2--header-se--common-part .mm-l3-link svg {
    display: initial;
    height: 18px;
    width: 18px;
  }
}

.se2--header-se--common-part .mm-extra-list {
  display: none;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-extra-list {
    display: flex;
  }
}

.se2--header-se--common-part .mm-extra-list-label {
  display: none;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-extra-list-label {
    display: initial;
  }
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-extra-list-mobile {
    display: none;
  }
}

[dir="ltr"] .se2--header-se--common-part .mm-extra-list-wrap {
  padding-left: 10px;
}

[dir="rtl"] .se2--header-se--common-part .mm-extra-list-wrap {
  padding-right: 10px;
}

[dir="ltr"] .se2--header-se--common-part .mm-extra-list-wrap {
  padding-right: 10px;
}

[dir="rtl"] .se2--header-se--common-part .mm-extra-list-wrap {
  padding-left: 10px;
}

.se2--header-se--common-part .mm-extra-list-wrap {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  line-height: 15px;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mm-extra-list-wrap {
    border-top: 1px solid var(--color-footer-gray);
  }
}

[dir="ltr"] .se2--header-se--common-part .mm-extra-list-wrap .mm-extra-link {
  margin-left: 20px;
}

[dir="rtl"] .se2--header-se--common-part .mm-extra-list-wrap .mm-extra-link {
  margin-right: 20px;
}

[dir="ltr"] .se2--header-se--common-part .mm-extra-list-wrap .mm-extra-link {
  margin-right: 20px;
}

[dir="rtl"] .se2--header-se--common-part .mm-extra-list-wrap .mm-extra-link {
  margin-left: 20px;
}

.se2--header-se--common-part .mm-extra-list-wrap .mm-extra-link {
  text-decoration: none;
}

.se2--header-se--common-part .mm-extra-list-wrap .mm-extra-link:hover {
  text-decoration: underline;
}

.se2--header-se--common-part .mm-extra-list-wrap svg {
  height: 15px;
  width: 15px;
}

[dir="rtl"] .se2--header-se--common-part .mm-extra-list-wrap svg {
  transform: rotate(180deg);
}

[dir="ltr"] .se2--header-se--common-part .mobile-metabar {
  padding-left: 30px;
}

[dir="rtl"] .se2--header-se--common-part .mobile-metabar {
  padding-right: 30px;
}

[dir="ltr"] .se2--header-se--common-part .mobile-metabar {
  padding-right: 10px;
}

[dir="rtl"] .se2--header-se--common-part .mobile-metabar {
  padding-left: 10px;
}

.se2--header-se--common-part .mobile-metabar {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

@media (min-width: 1200px) {
  .se2--header-se--common-part .mobile-metabar {
    display: none;
  }
}

.se2--header-se--common-part .mobile-metabar .login-wrap {
  order: 3;
}

.se2--header-se--common-part .mobile-metabar .login-menu {
  display: none;
}

.se2--header-se--common-part .mobile-metabar .login-menu a,
.se2--header-se--common-part .mobile-metabar .login-menu button {
  padding: 10px 0;
  font-size: 14px;
  line-height: 20px;
}

.se2--header-se--common-part .mobile-metabar .login-menu a {
  display: block;
  text-decoration: none;
  color: var(--color-text-gray);
}

.se2--header-se--common-part .mobile-metabar .login-menu a:hover {
  color: var(--color-sky-blue);
}

.se2--header-se--common-part .mobile-metabar .login-menu button {
  display: flex;
  width: 100%;
  border: none;
  margin: 0;
  background: none;
  cursor: pointer;
}

.se2--header-se--common-part .mobile-metabar .login-menu button:hover {
  color: var(--color-sky-blue);
}

.se2--header-se--common-part .mobile-metabar .login-wrap.open .login-menu {
  display: block;
}

/* app/components/HeaderSE/deferrals/countrySelector/index.scss */
/* app/stylesheets/mixins/stretchToFullWidth.scss */
/**
 * Mixin stretches element to whole available width of the container
 *
 *  @param {number} $block-start
 *  @param {number} $inline-end
 *  @param {number} $block-end
 *  @param {number} $inline-start
 *  @param {string} $position
 *
 *  Example:
 *    @include stretch-to-full-width();
 *    @include stretch-to-full-width($position: static, $block-start: unset);
 *    @include stretch-to-full-width($block-start: 100px, $inline-end: 200px, $block-end: 30px, $inline-start: 60px);
 *
 */
[dir="ltr"] .se2--header-se--country-selector {
  left: 0;
}
[dir="rtl"] .se2--header-se--country-selector {
  right: 0;
}
[dir="ltr"] .se2--header-se--country-selector {
  right: 0;
}
[dir="rtl"] .se2--header-se--country-selector {
  left: 0;
}
.se2--header-se--country-selector {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 60;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: var(--color-white);
  pointer-events: auto;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--country-selector {
    left: 0;
  }
  [dir="rtl"] .se2--header-se--country-selector {
    right: 0;
  }
  [dir="ltr"] .se2--header-se--country-selector {
    right: 0;
  }
  [dir="rtl"] .se2--header-se--country-selector {
    left: 0;
  }
  .se2--header-se--country-selector {
    position: absolute;
    top: 40px;
    bottom: 0;
    height: calc(100vh - 40px);
  }
}

.se2--header-se--country-selector .head {
  display: flex;
  justify-content: space-between;
  padding-left: var(--page-content-sorrounding-gap);
  padding-right: var(--page-content-sorrounding-gap);
}

.se2--header-se--country-selector .head.shadow {
  box-shadow: 0 1px 10px 0 rgba(51, 51, 51, 0.15);
}

.se2--header-se--country-selector .head-title {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 25px;
  line-height: 35px;
}

@media (min-width: 1200px) {
  .se2--header-se--country-selector .head-title {
    font-size: 30px;
    line-height: 40px;
  }
}

.se2--header-se--country-selector .button-close {
  position: static;
}

.se2--header-se--country-selector .body {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

html[dir="ltr"] .se2--header-se--country-selector .body .section {
  padding-left: var(--page-content-sorrounding-gap);
}

html[dir="rtl"] .se2--header-se--country-selector .body .section {
  padding-right: var(--page-content-sorrounding-gap);
}

html[dir="ltr"] .se2--header-se--country-selector .body .section {
  padding-right: var(--page-content-sorrounding-gap);
}

html[dir="rtl"] .se2--header-se--country-selector .body .section {
  padding-left: var(--page-content-sorrounding-gap);
}

html .se2--header-se--country-selector .body .section {
  border-bottom: 1px solid var(--color-footer-gray);
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 1200px) {
  html .se2--header-se--country-selector .body .section {
    border-bottom: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.se2--header-se--country-selector .body .section .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  line-height: 25px;
  color: var(--color-life-green);
}

@media (min-width: 1200px) {
  .se2--header-se--country-selector .body .section .title {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 30px;
  }
}

.se2--header-se--country-selector .body .section.open .title {
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .se2--header-se--country-selector .body .section .toggle-buttons-wrap {
    display: none;
  }
}

.se2--header-se--country-selector .body .section .toggle-buttons-wrap svg {
  height: 16px;
  width: 16px;
  color: var(--color-light-gray);
}

.se2--header-se--country-selector .body .section .icon-close {
  display: none;
}

.se2--header-se--country-selector .body .section .icon-open {
  display: block;
}

.se2--header-se--country-selector .body .section.open {
  background: var(--color-bg-gray);
}

@media (min-width: 1200px) {
  .se2--header-se--country-selector .body .section.open {
    background: none;
  }
}

.se2--header-se--country-selector .body .section.open .icon-open {
  display: none;
}

.se2--header-se--country-selector .body .section.open .icon-close {
  display: block;
}

.se2--header-se--country-selector .body .section .link-list {
  display: none;
  column-count: 2;
  column-gap: 20px;
}

.se2--header-se--country-selector .body .section .link-list a {
  width: 100%;
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--country-selector .body .section .link-list {
    text-align: left;
  }
  [dir="rtl"] .se2--header-se--country-selector .body .section .link-list {
    text-align: right;
  }
  .se2--header-se--country-selector .body .section .link-list {
    display: block;
    column-count: 4;
  }
}

[dir="ltr"] .se2--header-se--country-selector .body .section.open .link-list {
  text-align: left;
}

[dir="rtl"] .se2--header-se--country-selector .body .section.open .link-list {
  text-align: right;
}

.se2--header-se--country-selector .body .section.open .link-list {
  display: block;
}

/* app/components/HeaderSE/partials/geolocationBanner/index.scss */
html .se2--header-se--geolocation-banner {
  position: fixed;
  order: 1;
  width: 100%;
  padding: 15px var(--page-content-sorrounding-gap);
  box-shadow: 0 2px 5px 0 rgba(33, 33, 33, 0.15);
  color: var(--color-dark-gray);
  background: var(--color-white);
  pointer-events: auto;
}

@media (min-width: 1200px) {
  html .se2--header-se--geolocation-banner {
    z-index: 10;
    padding: 20px var(--page-content-sorrounding-gap);
  }
}

html[dir="ltr"] .se2--header-se--geolocation-banner .head {
  padding-right: 50px;
}

html[dir="rtl"] .se2--header-se--geolocation-banner .head {
  padding-left: 50px;
}

html .se2--header-se--geolocation-banner .head {
  margin-bottom: 15px;
  font-size: 25px;
  line-height: 35px;
}

html[dir="ltr"] .se2--header-se--geolocation-banner .icon-flag {
  margin-left: 10px;
}

html[dir="rtl"] .se2--header-se--geolocation-banner .icon-flag {
  margin-right: 10px;
}

@media (min-width: 1200px) {
  html[dir="ltr"] .se2--header-se--geolocation-banner .icon-flag {
    margin-left: 20px;
  }
  html[dir="rtl"] .se2--header-se--geolocation-banner .icon-flag {
    margin-right: 20px;
  }
}

html .se2--header-se--geolocation-banner .icon-flag,
html .se2--header-se--geolocation-banner .icon-close {
  height: 20px;
  width: 20px;
}

html .se2--header-se--geolocation-banner .body > div {
  margin-bottom: 15px;
}

html .se2--header-se--geolocation-banner .button-accept {
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  html[dir="ltr"] .se2--header-se--geolocation-banner .button-accept {
    margin-right: 15px;
  }
  html[dir="rtl"] .se2--header-se--geolocation-banner .button-accept {
    margin-left: 15px;
  }
}

html .se2--header-se--geolocation-banner .svg-ml {
  margin-top: 10px;
}

@media (min-width: 1200px) {
  html .se2--header-se--geolocation-banner .svg-ml {
    margin-top: 20px;
  }
}

html .se2--header-se--geolocation-banner .button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  border-radius: 2px;
  padding: 0 20px;
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

@media (min-width: 1200px) {
  html .se2--header-se--geolocation-banner .button {
    height: 48px;
    width: auto;
    max-width: 100%;
  }
}

html[dir="rtl"] .se2--header-se--geolocation-banner .button svg {
  transform: rotateY(180deg);
}

html .se2--header-se--geolocation-banner .button .button-text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

/* app/components/HeaderSE/partials/megaMenu/index.scss */
.se2--header-se--mega-menu {
  display: flex;
}

.se2--header-se--mega-menu.expanded {
  flex-direction: column;
}

.se2--header-se--mega-menu.expanded .navbar li .menu-nav-button:not(.hide) {
  display: flex;
  height: 63px;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
}

@media (min-width: 1200px) {
  .se2--header-se--mega-menu.expanded .navbar li .menu-nav-button:not(.hide) {
    display: unset;
    height: unset;
  }
}

.se2--header-se--mega-menu.expanded .navbar li .menu-nav-button.se2-icon-arrow-common::after {
  content: '';
}

[dir="ltr"] .se2--header-se--mega-menu.expanded .navbar li .menu-nav-button.open {
  padding-left: 20px;
}

[dir="rtl"] .se2--header-se--mega-menu.expanded .navbar li .menu-nav-button.open {
  padding-right: 20px;
}

[dir="ltr"] .se2--header-se--mega-menu.expanded .navbar li .menu-nav-button.open {
  padding-right: 54px;
}

[dir="rtl"] .se2--header-se--mega-menu.expanded .navbar li .menu-nav-button.open {
  padding-left: 54px;
}

.se2--header-se--mega-menu.expanded .navbar li .menu-nav-button.open {
  font-size: 14px;
}

[dir="ltr"] .se2--header-se--mega-menu.expanded .navbar li .menu-nav-button.open.se2-icon-arrow-common::before {
  margin-right: 5px;
}

[dir="rtl"] .se2--header-se--mega-menu.expanded .navbar li .menu-nav-button.open.se2-icon-arrow-common::before {
  margin-left: 5px;
}

.se2--header-se--mega-menu.expanded .navbar li .menu-nav-button.open.se2-icon-arrow-common::before {
  content: '\e802';
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  font-family: 'seCustomIconFont', sans-serif;
  font-weight: normal;
  font-style: normal;
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: normal;
  font-size: 220%;
  line-height: 14px;
  text-align: center;
  text-transform: none;
  color: var(--color-light-gray);
  transform: rotate(180deg);
  cursor: default;
  speak: never;
}

@media (min-width: 1200px) {
  .se2--header-se--mega-menu.expanded .navbar {
    margin-top: unset;
  }
}

.se2--header-se--mega-menu .navbar {
  width: 100%;
}

@media (min-width: 1200px) {
  .se2--header-se--mega-menu .navbar {
    width: unset;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--color-bg-gray-header);
  }
}

@media (min-width: 1200px) {
  .se2--header-se--mega-menu .navbar li {
    width: 300px;
  }
}

[dir="ltr"] .se2--header-se--mega-menu .navbar li .menu-nav-button {
  padding-left: 20px;
}

[dir="rtl"] .se2--header-se--mega-menu .navbar li .menu-nav-button {
  padding-right: 20px;
}

[dir="ltr"] .se2--header-se--mega-menu .navbar li .menu-nav-button {
  padding-right: 24px;
}

[dir="rtl"] .se2--header-se--mega-menu .navbar li .menu-nav-button {
  padding-left: 24px;
}

[dir="ltr"] .se2--header-se--mega-menu .navbar li .menu-nav-button {
  text-align: left;
}

[dir="rtl"] .se2--header-se--mega-menu .navbar li .menu-nav-button {
  text-align: right;
}

.se2--header-se--mega-menu .navbar li .menu-nav-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-footer-gray);
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-gray);
  background: none;
  transition: background-color 0.1s linear 0.1s;
  cursor: pointer;
}

.se2--header-se--mega-menu .navbar li .menu-nav-button.se2-icon-arrow-common::after {
  width: 20px;
  font-size: 150%;
  line-height: 10px;
  color: var(--color-light-gray);
}

.se2--header-se--mega-menu .navbar li .menu-nav-button.open {
  text-align: center;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--mega-menu .navbar li .menu-nav-button {
    padding-left: 40px;
  }
  [dir="rtl"] .se2--header-se--mega-menu .navbar li .menu-nav-button {
    padding-right: 40px;
  }
  [dir="ltr"] .se2--header-se--mega-menu .navbar li .menu-nav-button {
    padding-right: 15px;
  }
  [dir="rtl"] .se2--header-se--mega-menu .navbar li .menu-nav-button {
    padding-left: 15px;
  }
  .se2--header-se--mega-menu .navbar li .menu-nav-button {
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 700;
  }
  .se2--header-se--mega-menu .navbar li .menu-nav-button:hover, .se2--header-se--mega-menu .navbar li .menu-nav-button.active {
    color: var(--color-sky-blue);
  }
  .se2-accessibility .se2--header-se--mega-menu .navbar li .menu-nav-button:hover, .se2-accessibility .se2--header-se--mega-menu .navbar li .menu-nav-button.active {
    color: var(--color-blue);
  }
  .se2--header-se--mega-menu .navbar li .menu-nav-button.active {
    background: var(--color-white);
  }
  [dir="ltr"] .se2--header-se--mega-menu .navbar li .menu-nav-button.open {
    padding-right: 15px;
  }
  [dir="rtl"] .se2--header-se--mega-menu .navbar li .menu-nav-button.open {
    padding-left: 15px;
  }
  .se2--header-se--mega-menu .navbar li .menu-nav-button.open {
    text-align: center;
  }
}

[dir="ltr"] .se2--header-se--mega-menu .navbar li .menu-nav-button svg {
  margin-left: 20px;
}

[dir="rtl"] .se2--header-se--mega-menu .navbar li .menu-nav-button svg {
  margin-right: 20px;
}

.se2--header-se--mega-menu .navbar li .menu-nav-button svg {
  flex-shrink: 0;
  color: var(--color-light-gray);
}

@media (min-width: 1200px) {
  .se2--header-se--mega-menu .navbar li .menu-nav-button svg {
    height: 10px;
    width: 10px;
  }
}

.se2--header-se--mega-menu .submenu {
  position: relative;
  flex-grow: 1;
  width: auto;
}

@media (min-width: 1200px) {
  .se2--header-se--mega-menu .submenu {
    display: unset;
  }
}

[dir="ltr"] .se2--header-se--mega-menu .submenu .menu-item {
  left: unset;
}

[dir="rtl"] .se2--header-se--mega-menu .submenu .menu-item {
  right: unset;
}

.se2--header-se--mega-menu .submenu .menu-item {
  height: 100%;
  width: 100%;
  background-color: var(--color-white);
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--mega-menu .submenu .menu-item {
    padding-left: 40px;
  }
  [dir="rtl"] .se2--header-se--mega-menu .submenu .menu-item {
    padding-right: 40px;
  }
  [dir="ltr"] .se2--header-se--mega-menu .submenu .menu-item {
    padding-right: 40px;
  }
  [dir="rtl"] .se2--header-se--mega-menu .submenu .menu-item {
    padding-left: 40px;
  }
  .se2--header-se--mega-menu .submenu .menu-item {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .se2--header-se--mega-menu .submenu .menu-item .head {
    margin-bottom: 20px;
  }
}

.se2--header-se--mega-menu .submenu .menu-item .head .mm-see-all-title-wrapper {
  width: 100%;
}

.se2--header-se--mega-menu .submenu .menu-item .level2-list {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 1200px) {
  .header-apc .se2--header-se--mega-menu .submenu .menu-item .level2-list {
    display: block;
    columns: 4 246px;
    column-gap: 20px;
  }
}

.se2--header-se--mega-menu .submenu .menu-item .level2-list .level2-item {
  width: 100%;
}

.se2--header-se--mega-menu .submenu .menu-item .level2-list .level2-item .mm-l2-item {
  border-bottom: 1px solid var(--color-footer-gray);
}

@media (min-width: 1200px) {
  .se2--header-se--mega-menu .submenu .menu-item .level2-list .level2-item .mm-l2-item {
    border-bottom: none;
  }
}

.se2--header-se--mega-menu .submenu .menu-item .level2-list .level2-item .mm-l2-item .mm-l2-button.mobile {
  font-weight: 700;
}

.se2--header-se--mega-menu .submenu .menu-item .level2-list .level2-item .mm-l2-item .mm-l2-button.mobile svg {
  display: none;
}

[dir="ltr"] .se2--header-se--mega-menu .submenu .menu-item .level2-list .level2-item .mm-l2-item .mm-l3-list .mm-l2-title.show {
  padding-left: 20px;
}

[dir="rtl"] .se2--header-se--mega-menu .submenu .menu-item .level2-list .level2-item .mm-l2-item .mm-l3-list .mm-l2-title.show {
  padding-right: 20px;
}

[dir="ltr"] .se2--header-se--mega-menu .submenu .menu-item .level2-list .level2-item .mm-l2-item .mm-l3-list .mm-l2-title.show {
  padding-right: 15px;
}

[dir="rtl"] .se2--header-se--mega-menu .submenu .menu-item .level2-list .level2-item .mm-l2-item .mm-l3-list .mm-l2-title.show {
  padding-left: 15px;
}

.se2--header-se--mega-menu .submenu .menu-item .level2-list .level2-item .mm-l2-item .mm-l3-list .mm-l2-title.show {
  border-bottom: 1px solid var(--color-footer-gray);
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-gray);
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--mega-menu .submenu .menu-item .level2-list .level2-item {
    margin-right: 10px;
  }
  [dir="rtl"] .se2--header-se--mega-menu .submenu .menu-item .level2-list .level2-item {
    margin-left: 10px;
  }
  .se2--header-se--mega-menu .submenu .menu-item .level2-list .level2-item {
    width: 246px;
    margin-bottom: 20px;
  }
  .header-apc .se2--header-se--mega-menu .submenu .menu-item .level2-list .level2-item {
    height: fit-content;
    width: 100%;
    margin: 0;
  }
}

/* app/components/HeaderSE/partials/menuSectionTopLink/index.scss */
.se2--header-se--menu-section-top-link {
  display: block;
  width: 100%;
  text-decoration: none;
  background-color: var(--color-footer-gray);
}

html[dir="ltr"] .se2--header-se--menu-section-top-link {
  margin-left: 0;
}

html[dir="rtl"] .se2--header-se--menu-section-top-link {
  margin-right: 0;
}

html[dir="ltr"] .se2--header-se--menu-section-top-link {
  margin-right: 10px;
}

html[dir="rtl"] .se2--header-se--menu-section-top-link {
  margin-left: 10px;
}

html .se2--header-se--menu-section-top-link {
  padding: 10px;
}

.se2--header-se--menu-section-top-link svg {
  height: 20px;
  width: 20px;
}

[dir="rtl"] .se2--header-se--menu-section-top-link svg {
  transform: rotate(180deg);
}

[dir="rtl"] .se2--header-se--menu-section-top-link svg.openInNewWindow {
  transform: rotate(270deg);
}

/* app/components/HeaderSE/partials/metabar/index.scss */
.se2--header-se--metabar {
  --logo-placeholder-width: 225px;
  --page-content-sorrounding-gap: 16px;
}

@media (min-width: 1200px) {
  .se2--header-se--metabar {
    --page-content-sorrounding-gap: 40px;
  }
}

html[dir="ltr"] .se2--header-se--metabar {
  padding-left: var(--page-content-sorrounding-gap);
}

html[dir="rtl"] .se2--header-se--metabar {
  padding-right: var(--page-content-sorrounding-gap);
}

html[dir="ltr"] .se2--header-se--metabar {
  padding-right: var(--page-content-sorrounding-gap);
}

html[dir="rtl"] .se2--header-se--metabar {
  padding-left: var(--page-content-sorrounding-gap);
}

html .se2--header-se--metabar {
  position: relative;
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  background-color: var(--color-bg-gray-new);
  pointer-events: auto;
}

@media (min-width: 1200px) {
  html .se2--header-se--metabar {
    display: flex;
  }
}

[dir="ltr"] header.branded-header .se2--header-se--metabar {
  padding-left: calc(var(--page-content-sorrounding-gap) + var(--logo-placeholder-width));
}

[dir="rtl"] header.branded-header .se2--header-se--metabar {
  padding-right: calc(var(--page-content-sorrounding-gap) + var(--logo-placeholder-width));
}

.se2--header-se--metabar .site-info,
.se2--header-se--metabar .user-list {
  display: flex;
  justify-content: center;
}

.se2--header-se--metabar .site-info {
  height: 100%;
}

.se2--header-se--metabar .site-info .icon-wrapper {
  display: flex;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--metabar .site-info .language-selector-toggler {
    padding-left: 0;
  }
  [dir="rtl"] .se2--header-se--metabar .site-info .language-selector-toggler {
    padding-right: 0;
  }
  [dir="ltr"] .se2--header-se--metabar .site-info .language-selector-toggler {
    padding-right: 10px;
  }
  [dir="rtl"] .se2--header-se--metabar .site-info .language-selector-toggler {
    padding-left: 10px;
  }
}

.se2--header-se--metabar .user-list {
  height: 100%;
}

[dir="ltr"] .se2--header-se--metabar .user-list button:first-of-type {
  padding-left: 10px;
}

[dir="rtl"] .se2--header-se--metabar .user-list button:first-of-type {
  padding-right: 10px;
}

[dir="ltr"] .se2--header-se--metabar .user-list button:first-of-type {
  margin-left: 20px;
}

[dir="rtl"] .se2--header-se--metabar .user-list button:first-of-type {
  margin-right: 20px;
}

.se2--header-se--metabar .user-list .login-wrap {
  position: relative;
}

.se2--header-se--metabar .user-list .login-menu {
  position: absolute;
  z-index: 1;
  display: none;
  padding: 15px;
  background: var(--color-bg-gray);
}

[dir="ltr"] .se2--header-se--metabar .user-list .login-menu::before {
  left: calc(50% - 7px);
}

[dir="rtl"] .se2--header-se--metabar .user-list .login-menu::before {
  right: calc(50% - 7px);
}

.se2--header-se--metabar .user-list .login-menu::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: -5px;
  z-index: 1;
  display: block;
  height: 9px;
  width: 9px;
  box-shadow: 1px -1px 2px 0 rgba(51, 51, 51, 0.14);
  background: var(--color-white);
  opacity: 0;
  transform: rotate(-55deg) skewY(22deg);
}

.se2--header-se--metabar .user-list .login-menu a,
.se2--header-se--metabar .user-list .login-menu button {
  padding: 0 15px;
  margin-bottom: 15px;
}

.se2--header-se--metabar .user-list .login-menu a {
  display: block;
}

.se2--header-se--metabar .user-list .login-menu button {
  display: flex;
  width: 100%;
  border: none;
  margin: 0;
  background: none;
  cursor: pointer;
}

.se2--header-se--metabar .user-list .login-menu button:hover {
  color: var(--color-sky-blue);
}

.se2--header-se--metabar .user-list .login-wrap.open .login-menu {
  display: block;
}

.se2--header-se--metabar .user-list .login-wrap.open .login-menu::before {
  opacity: 1;
}

/* app/components/HeaderSE/partials/metabarButton/index.scss */
.se2--header-se--metabar-button {
  display: flex;
  align-items: center;
  height: 100%;
  width: fit-content;
  border: 0;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
  background: none;
  transition: background-color 0.5s;
  cursor: pointer;
}

[dir="ltr"] .se2--header-se--metabar-button:first-child {
  padding-left: 0;
}

[dir="rtl"] .se2--header-se--metabar-button:first-child {
  padding-right: 0;
}

.se2--header-se--metabar-button.se2-grey-blue-link:hover {
  text-decoration: none;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--metabar-button {
    padding-left: 5px;
  }
  [dir="rtl"] .se2--header-se--metabar-button {
    padding-right: 5px;
  }
  [dir="ltr"] .se2--header-se--metabar-button {
    padding-right: 5px;
  }
  [dir="rtl"] .se2--header-se--metabar-button {
    padding-left: 5px;
  }
  .se2--header-se--metabar-button {
    order: unset;
    padding-top: 0;
    padding-bottom: 0;
  }
  .se2--header-se--metabar-button:hover {
    color: var(--color-black);
    background-color: var(--color-footer-gray);
  }
}

[dir="ltr"] .se2--header-se--metabar-button > svg {
  margin-right: 5px;
}

[dir="rtl"] .se2--header-se--metabar-button > svg {
  margin-left: 5px;
}

.se2--header-se--metabar-button.se2-icon-arrow-common::after {
  margin: 0;
  font-size: 150%;
  color: var(--color-light-gray);
}

[dir="rtl"] .se2--header-se--metabar-button.se2-icon-arrow-common::after {
  transform: rotate(180deg);
}

@media (min-width: 1200px) {
  .se2--header-se--metabar-button.se2-icon-arrow-common::after {
    transform: rotate(90deg);
  }
  [dir="rtl"] .se2--header-se--metabar-button.se2-icon-arrow-common::after {
    transform: rotate(90deg);
  }
}

@media (min-width: 1200px) {
  .se2--header-se--metabar-button.is-expanded.se2-icon-arrow-common::after {
    transform: rotate(-90deg);
  }
}

/* app/components/HeaderSE/partials/metabarLink/index.scss */
/* app/stylesheets/mixins/stretchToFullWidth.scss */
/**
 * Mixin stretches element to whole available width of the container
 *
 *  @param {number} $block-start
 *  @param {number} $inline-end
 *  @param {number} $block-end
 *  @param {number} $inline-start
 *  @param {string} $position
 *
 *  Example:
 *    @include stretch-to-full-width();
 *    @include stretch-to-full-width($position: static, $block-start: unset);
 *    @include stretch-to-full-width($block-start: 100px, $inline-end: 200px, $block-end: 30px, $inline-start: 60px);
 *
 */
/* app/stylesheets/mixins/visuallyhidden.scss */
.se2--header-se--metabar-link [data-count='0'] .cart-count {
  position: absolute;
  height: 1px;
  width: 1px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

html .se2--header-se--metabar-link {
  display: flex;
  align-items: center;
  width: fit-content;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 1200px) {
  html[dir="ltr"] .se2--header-se--metabar-link {
    padding-left: 10px;
  }
  html[dir="rtl"] .se2--header-se--metabar-link {
    padding-right: 10px;
  }
  html[dir="ltr"] .se2--header-se--metabar-link {
    padding-right: 10px;
  }
  html[dir="rtl"] .se2--header-se--metabar-link {
    padding-left: 10px;
  }
  html .se2--header-se--metabar-link {
    order: unset;
    padding-top: 0;
    padding-bottom: 0;
  }
  html[dir="ltr"] .se2--header-se--metabar-link:last-of-type {
    padding-right: 0;
  }
  html[dir="rtl"] .se2--header-se--metabar-link:last-of-type {
    padding-left: 0;
  }
  html .se2--header-se--metabar-link:hover {
    text-decoration: none;
    color: var(--color-sky-blue);
  }
}

.se2--header-se--metabar-link svg {
  height: 20px;
  width: 20px;
}

[dir="rtl"] .se2--header-se--metabar-link svg.openInNewWindow {
  transform: rotate(270deg);
}

[dir="ltr"] .se2--header-se--metabar-link .icon-wrapper {
  margin-right: 5px;
}

[dir="rtl"] .se2--header-se--metabar-link .icon-wrapper {
  margin-left: 5px;
}

.se2--header-se--metabar-link .icon-wrapper {
  position: relative;
  display: flex;
}

[dir="ltr"] .se2--header-se--metabar-link .icon-wrapper.favorite-docs.se2-icon-doc {
  margin-left: 3px;
}

[dir="rtl"] .se2--header-se--metabar-link .icon-wrapper.favorite-docs.se2-icon-doc {
  margin-right: 3px;
}

[dir="ltr"] .se2--header-se--metabar-link .icon-wrapper.favorite-docs.se2-icon-doc {
  margin-right: 6px;
}

[dir="rtl"] .se2--header-se--metabar-link .icon-wrapper.favorite-docs.se2-icon-doc {
  margin-left: 6px;
}

.se2--header-se--metabar-link .icon-wrapper.favorite-docs.se2-icon-doc {
  height: 20px;
}

.se2--header-se--metabar-link .icon-wrapper.favorite-docs.se2-icon-doc::before {
  font-size: 147%;
  line-height: normal;
}

.se2--header-se--metabar-link .label-wrapper .se2-icon-new-tab::after {
  font-size: 145%;
}

[dir="ltr"] .se2--header-se--metabar-link [data-count] .cart-count {
  left: calc(100% - 3px);
}

[dir="rtl"] .se2--header-se--metabar-link [data-count] .cart-count {
  right: calc(100% - 3px);
}

[dir="ltr"] .se2--header-se--metabar-link [data-count] .cart-count {
  padding-left: 2px;
}

[dir="rtl"] .se2--header-se--metabar-link [data-count] .cart-count {
  padding-right: 2px;
}

[dir="ltr"] .se2--header-se--metabar-link [data-count] .cart-count {
  padding-right: 2px;
}

[dir="rtl"] .se2--header-se--metabar-link [data-count] .cart-count {
  padding-left: 2px;
}

[dir="ltr"] .se2--header-se--metabar-link [data-count] .cart-count {
  margin-left: -50%;
}

[dir="rtl"] .se2--header-se--metabar-link [data-count] .cart-count {
  margin-right: -50%;
}

.se2--header-se--metabar-link [data-count] .cart-count {
  content: attr(data-count);
  position: absolute;
  top: 0;
  min-width: 15px;
  border-radius: 10px;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: var(--color-bg-gray);
  background-color: var(--color-sky-blue);
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--metabar-link [data-count] .cart-count {
    left: 100%;
  }
  [dir="rtl"] .se2--header-se--metabar-link [data-count] .cart-count {
    right: 100%;
  }
  [dir="ltr"] .se2--header-se--metabar-link [data-count] .cart-count {
    padding-left: 3px;
  }
  [dir="rtl"] .se2--header-se--metabar-link [data-count] .cart-count {
    padding-right: 3px;
  }
  [dir="ltr"] .se2--header-se--metabar-link [data-count] .cart-count {
    padding-right: 3px;
  }
  [dir="rtl"] .se2--header-se--metabar-link [data-count] .cart-count {
    padding-left: 3px;
  }
  .se2--header-se--metabar-link [data-count] .cart-count {
    min-width: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;
  }
}

.se2--header-se--metabar-link [data-count] .cart-count:empty {
  display: none;
}

/* app/components/HeaderSE/partials/predictiveSearch/index.scss */
html .se2--header-se--predictive-search {
  border-top: 1px solid var(--color-footer-gray);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 1200px) {
  html .se2--header-se--predictive-search {
    border: 0;
    padding: 0;
  }
}

/* app/components/HeaderSE/partials/promoBanner/index.scss */
.se2--header-se--promo-banner {
  --color-white: #fff;
  --color-sky-blue: #42b4e6;
}

.se2--header-se--promo-banner.notification-container {
  position: fixed;
  top: 120px;
  left: 0;
  right: 0;
  z-index: 100;
  display: block;
  background: transparent;
  opacity: 1;
  transition: inset-block-start 0.25s ease-out, opacity 0.25s;
}

@media (min-width: 1200px) {
  .se2--header-se--promo-banner.notification-container {
    display: none;
  }
}

.se2--header-se--promo-banner .notification-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100vw;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: var(--color-white);
}

.se2--header-se--promo-banner .buttons-container {
  display: flex;
  align-items: center;
}

.se2--header-se--promo-banner .buttons-container .se2-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border: 0;
  border-radius: 5px;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
  color: var(--color-white);
  background-color: var(--color-sky-blue);
  cursor: pointer;
}

[dir="ltr"] .se2--header-se--promo-banner .notification-btn {
  margin-right: 14px;
}

[dir="rtl"] .se2--header-se--promo-banner .notification-btn {
  margin-left: 14px;
}

.se2--header-se--promo-banner .notification-btn {
  max-width: 120px;
}

.se2--header-se--promo-banner .notification-btn svg {
  display: none;
}

[dir="ltr"] .se2--header-se--promo-banner .svg-container {
  margin-right: 17px;
}

[dir="rtl"] .se2--header-se--promo-banner .svg-container {
  margin-left: 17px;
}

.se2--header-se--promo-banner .svg-container {
  height: 40px;
  max-width: 132px;
}

.se2--header-se--promo-banner .svg-container img {
  height: 40px;
  width: 132px;
}

.se2--header-se--promo-banner .close-button {
  cursor: pointer;
}

.se2--header-se--promo-banner .close-button.se2-icon-close::before {
  font-size: 120%;
  color: var(--color-dark-gray);
}

.se2--header-se--promo-banner .close-button:hover {
  opacity: 0.8;
}

.se2--header-se--promo-banner .close-button:active {
  opacity: 1;
}

.se2--header-se--promo-banner .close-button:disabled {
  opacity: 0.3;
}

/* app/components/HeaderSE/partials/search/index.scss */
html .search-menu-wrapper:not(.menu-wrapper-apc) > .se2--header-se--search {
  border-top: 1px solid var(--color-footer-gray);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 1200px) {
  html .search-menu-wrapper:not(.menu-wrapper-apc) > .se2--header-se--search {
    border: 0;
    padding: 0;
  }
}

html .se2--header-se--search.show {
  display: block;
}

@media (min-width: 1200px) {
  html .se2--header-se--search.show {
    display: flex;
  }
}

@media (min-width: 1200px) {
  html .se2--header-se--search.show-enabled {
    display: flex;
  }
}

[dir="ltr"] .se2--header-se--search #GlobalSearchApp {
  right: 98px;
}

[dir="rtl"] .se2--header-se--search #GlobalSearchApp {
  left: 98px;
}

.se2--header-se--search #GlobalSearchApp {
  position: absolute;
  top: 12px;
  z-index: 0;
  display: block;
  flex-grow: 1;
  height: 30px;
  border: none;
}

.se2--header-se--search #GlobalSearchApp .hide-mobile {
  display: none;
}

.se2--header-se--search #GlobalSearchApp div:first-child {
  margin-top: 5px;
}

.se2--header-se--search #GlobalSearchApp button:not(.search-reset):not(.search-submit) {
  padding: 0 0 3px 0;
  cursor: pointer;
}

.se2--header-se--search #GlobalSearchApp svg {
  height: 20px;
  width: 20px;
  color: var(--color-text-gray);
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--search #GlobalSearchApp {
    right: unset;
  }
  [dir="rtl"] .se2--header-se--search #GlobalSearchApp {
    left: unset;
  }
  .se2--header-se--search #GlobalSearchApp {
    position: relative;
    top: unset;
    z-index: 20;
  }
  .se2--header-se--search #GlobalSearchApp .hide-mobile {
    display: flex;
  }
  .se2--header-se--search #GlobalSearchApp div:first-child {
    margin-top: 0;
  }
  .se2--header-se--search #GlobalSearchApp button:not(.search-reset):not(.search-submit) {
    padding: 0;
  }
}

.se2--header-se--search .shopping-cart.btn-nav {
  display: none;
}

.se2--header-se--search .shopping-cart.btn-nav [data-count='0'] .cart-count {
  display: none;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--search .shopping-cart.btn-nav {
    margin-left: 16px;
  }
  [dir="rtl"] .se2--header-se--search .shopping-cart.btn-nav {
    margin-right: 16px;
  }
  .se2--header-se--search .shopping-cart.btn-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    border-radius: 4px;
    padding: 0;
    background-color: var(--color-white);
  }
  .se2--header-se--search .shopping-cart.btn-nav svg {
    height: 20px;
    width: 20px;
  }
}

.se2--header-se--search .search-layer,
.se2--header-se--search .sdl-header-se_search-layer {
  display: flex;
  flex-grow: 1;
}

.se2--header-se--search .search-bar,
.se2--header-se--search .sdl-header-se_search-bar {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  height: 44px;
  border: 1px solid var(--color-light-gray);
  border-radius: 4px;
  background-color: var(--color-white);
}

.se2--header-se--search .search-bar:hover,
.se2--header-se--search .sdl-header-se_search-bar:hover {
  border: 1px solid var(--color-dark-gray);
}

@media (min-width: 1200px) {
  .se2--header-se--search .search-bar,
  .se2--header-se--search .sdl-header-se_search-bar {
    height: 40px;
    max-width: 700px;
  }
  .transparent.reduced .se2--header-se--search .search-bar,
  .se2-accessibility .se2--header-se--search .search-bar, .transparent.reduced
  .se2--header-se--search .sdl-header-se_search-bar,
  .se2-accessibility
  .se2--header-se--search .sdl-header-se_search-bar {
    background-color: var(--color-white);
  }
  .transparent.reduced .se2--header-se--search .search-bar:hover,
  .se2-accessibility .se2--header-se--search .search-bar:hover, .transparent.reduced
  .se2--header-se--search .sdl-header-se_search-bar:hover,
  .se2-accessibility
  .se2--header-se--search .sdl-header-se_search-bar:hover {
    background-color: var(--color-white);
  }
}

[dir="ltr"] .se2--header-se--search .search-field,[dir="ltr"] 
.se2--header-se--search .sdl-header-se_search-field {
  padding-left: 40px;
}

[dir="rtl"] .se2--header-se--search .search-field,[dir="rtl"] 
.se2--header-se--search .sdl-header-se_search-field {
  padding-right: 40px;
}

[dir="ltr"] .se2--header-se--search .search-field,[dir="ltr"] 
.se2--header-se--search .sdl-header-se_search-field {
  padding-right: 10px;
}

[dir="rtl"] .se2--header-se--search .search-field,[dir="rtl"] 
.se2--header-se--search .sdl-header-se_search-field {
  padding-left: 10px;
}

.se2--header-se--search .search-field,
.se2--header-se--search .sdl-header-se_search-field {
  flex-grow: 1;
  order: 1;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  text-overflow: ellipsis;
  background-color: transparent;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--search .search-field,[dir="ltr"] 
  .se2--header-se--search .sdl-header-se_search-field {
    padding-left: 12px;
  }
  [dir="rtl"] .se2--header-se--search .search-field,[dir="rtl"] 
  .se2--header-se--search .sdl-header-se_search-field {
    padding-right: 12px;
  }
  .se2--header-se--search .search-field,
  .se2--header-se--search .sdl-header-se_search-field {
    order: 0;
  }
  .transparent .se2--header-se--search .search-field, .transparent
  .se2--header-se--search .sdl-header-se_search-field {
    color: var(--color-text-gray);
  }
  .transparent .se2--header-se--search .search-field::placeholder, .transparent
  .se2--header-se--search .sdl-header-se_search-field::placeholder {
    color: var(--color-dark-gray);
  }
  .transparent.reduced .se2--header-se--search .search-field,
  .se2-accessibility .se2--header-se--search .search-field, .transparent.reduced
  .se2--header-se--search .sdl-header-se_search-field,
  .se2-accessibility
  .se2--header-se--search .sdl-header-se_search-field {
    color: var(--color-black);
  }
  .transparent.reduced .se2--header-se--search .search-field::placeholder,
  .se2-accessibility .se2--header-se--search .search-field::placeholder, .transparent.reduced
  .se2--header-se--search .sdl-header-se_search-field::placeholder,
  .se2-accessibility
  .se2--header-se--search .sdl-header-se_search-field::placeholder {
    color: initial;
  }
}

.se2--header-se--search .search-field ::placeholder,
.se2--header-se--search .search-field :-ms-input-placeholder,
.se2--header-se--search .search-field ::-ms-input-placeholder,
.se2--header-se--search .sdl-header-se_search-field ::placeholder,
.se2--header-se--search .sdl-header-se_search-field :-ms-input-placeholder,
.se2--header-se--search .sdl-header-se_search-field ::-ms-input-placeholder {
  color: var(--color-light-gray);
  opacity: 1;
}

.se2--header-se--search .search-field::-webkit-search-cancel-button,
.se2--header-se--search .sdl-header-se_search-field::-webkit-search-cancel-button {
  display: none;
}

.se2--header-se--search .search-field::-ms-clear,
.se2--header-se--search .sdl-header-se_search-field::-ms-clear {
  display: none;
}

[dir="ltr"] .se2--header-se--search .search-reset,[dir="ltr"] 
.se2--header-se--search .sdl-header-se_search-reset {
  right: 36px;
}

[dir="rtl"] .se2--header-se--search .search-reset,[dir="rtl"] 
.se2--header-se--search .sdl-header-se_search-reset {
  left: 36px;
}

[dir="ltr"] .se2--header-se--search .search-reset,[dir="ltr"] 
.se2--header-se--search .sdl-header-se_search-reset {
  padding-left: 10px;
}

[dir="rtl"] .se2--header-se--search .search-reset,[dir="rtl"] 
.se2--header-se--search .sdl-header-se_search-reset {
  padding-right: 10px;
}

[dir="ltr"] .se2--header-se--search .search-reset,[dir="ltr"] 
.se2--header-se--search .sdl-header-se_search-reset {
  padding-right: 10px;
}

[dir="rtl"] .se2--header-se--search .search-reset,[dir="rtl"] 
.se2--header-se--search .sdl-header-se_search-reset {
  padding-left: 10px;
}

.se2--header-se--search .search-reset,
.se2--header-se--search .sdl-header-se_search-reset {
  position: absolute;
  top: 0;
  bottom: 0;
  display: none;
  align-items: center;
  border: none;
  color: var(--color-light-gray);
  background: transparent;
}

.se2--header-se--search .search-reset:hover,
.se2--header-se--search .sdl-header-se_search-reset:hover {
  color: var(--color-dark-gray);
}

@media (min-width: 1200px) {
  .se2--header-se--search .search-reset,
  .se2--header-se--search .sdl-header-se_search-reset {
    top: 40px;
  }
}

.se2--header-se--search .search-reset svg,
.se2--header-se--search .sdl-header-se_search-reset svg {
  height: 14px;
  width: 14px;
}

.se2--header-se--search .search-submit,
.se2--header-se--search .sdl-header-se_search-submit {
  position: absolute;
  order: 0;
  height: 38px;
  width: 40px;
  border: none;
  padding: 0;
  color: var(--color-light-gray);
  background-color: transparent;
  cursor: pointer;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--search .search-submit,[dir="ltr"] 
  .se2--header-se--search .sdl-header-se_search-submit {
    border-left: 1px solid var(--color-light-gray);
  }
  [dir="rtl"] .se2--header-se--search .search-submit,[dir="rtl"] 
  .se2--header-se--search .sdl-header-se_search-submit {
    border-right: 1px solid var(--color-light-gray);
  }
  .se2--header-se--search .search-submit,
  .se2--header-se--search .sdl-header-se_search-submit {
    position: static;
    order: 1;
    border-color: var(--color-dark-gray);
    color: var(--color-white);
    background-color: var(--color-dark-gray);
  }
}

.se2--header-se--search .search-submit:hover,
.se2--header-se--search .sdl-header-se_search-submit:hover {
  border-radius: 4px;
  color: var(--color-text-gray);
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--search .search-submit:hover,[dir="ltr"] 
  .se2--header-se--search .sdl-header-se_search-submit:hover {
    border-left: 1px solid var(--color-dark-gray);
  }
  [dir="rtl"] .se2--header-se--search .search-submit:hover,[dir="rtl"] 
  .se2--header-se--search .sdl-header-se_search-submit:hover {
    border-right: 1px solid var(--color-dark-gray);
  }
  .se2--header-se--search .search-submit:hover,
  .se2--header-se--search .sdl-header-se_search-submit:hover {
    color: var(--color-dark-gray);
    background-color: var(--color-white);
  }
}

.se2--header-se--search .search-submit > svg,
.se2--header-se--search .sdl-header-se_search-submit > svg {
  height: 20px;
  width: 20px;
}

.se2--header-se--search .search-field:valid ~ .sdl-header-se_search-reset,
.ie .se2--header-se--search .search-field.valid ~ .sdl-header-se_search-reset,
.se2--header-se--search .sdl-header-se_search-field:valid ~ .sdl-header-se_search-reset,
.ie
.se2--header-se--search .sdl-header-se_search-field.valid ~ .sdl-header-se_search-reset {
  display: flex;
}

.ie .se2--header-se--search .search-field:not(.valid) ~ .sdl-header-se_search-reset, .ie
.se2--header-se--search .sdl-header-se_search-field:not(.valid) ~ .sdl-header-se_search-reset {
  display: none;
}

.ie .se2--header-se--search .search-field:not(.valid) ~ .sdl-header-se_search-submit, .ie
.se2--header-se--search .sdl-header-se_search-field:not(.valid) ~ .sdl-header-se_search-submit {
  color: var(--color-light-gray);
}

.ie .se2--header-se--search .search-field:not(.valid) ~ .sdl-header-se_search-submit:hover, .ie
.se2--header-se--search .sdl-header-se_search-field:not(.valid) ~ .sdl-header-se_search-submit:hover {
  color: var(--color-life-green);
}

.se2-accessibility .ie .se2--header-se--search .search-field:not(.valid) ~ .sdl-header-se_search-submit:hover, .se2-accessibility .ie
.se2--header-se--search .sdl-header-se_search-field:not(.valid) ~ .sdl-header-se_search-submit:hover {
  color: var(--color-text-gray);
}

/* app/components/HeaderSE/partials/signInForAPC/index.scss */
.se2--header-se--sign-in-for-apc {
  position: relative;
  z-index: 30;
  pointer-events: visible;
}

[dir="ltr"] .se2--header-se--sign-in-for-apc .sign-in-menu {
  right: 0;
}

[dir="rtl"] .se2--header-se--sign-in-for-apc .sign-in-menu {
  left: 0;
}

.se2--header-se--sign-in-for-apc .sign-in-menu {
  position: absolute;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 155px;
  width: 230px;
  box-shadow: rgba(51, 51, 51, 0.14) 0 0 2px 0, rgba(51, 51, 51, 0.1) 0 7px 15px 0;
  background-color: var(--color-white);
  animation: 0.3s ease-in hideList;
}

.se2--header-se--sign-in-for-apc .sign-in-menu .sign-in-link,
.se2--header-se--sign-in-for-apc .sign-in-menu .register-now-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: calc(100% - 30px);
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  margin: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
}

.se2--header-se--sign-in-for-apc .sign-in-menu .sign-in-link {
  color: var(--color-white);
  background-color: var(--color-sky-blue);
}

.se2--header-se--sign-in-for-apc .sign-in-menu .sign-in-link:hover {
  background-color: var(--color-sky-blue-light);
}

.se2--header-se--sign-in-for-apc .sign-in-menu .register-now-link {
  border: 1px solid var(--color-sky-blue);
  color: var(--color-sky-blue);
  background-color: var(--color-white);
}

.se2--header-se--sign-in-for-apc .sign-in-menu .register-now-link:hover {
  border-width: 2px;
}

.se2--header-se--sign-in-for-apc .sign-in-menu .title {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-dark-gray);
}

.se2--header-se--sign-in-for-apc .sign-in-menu .title::before, .se2--header-se--sign-in-for-apc .sign-in-menu .title::after {
  content: '';
  flex-grow: 1;
  height: 1px;
  background-color: var(--color-footer-gray);
}

[dir="ltr"] .se2--header-se--sign-in-for-apc .sign-in-menu .title::before {
  margin-right: 10px;
}

[dir="rtl"] .se2--header-se--sign-in-for-apc .sign-in-menu .title::before {
  margin-left: 10px;
}

[dir="ltr"] .se2--header-se--sign-in-for-apc .sign-in-menu .title::after {
  margin-left: 10px;
}

[dir="rtl"] .se2--header-se--sign-in-for-apc .sign-in-menu .title::after {
  margin-right: 10px;
}

[dir="ltr"] .se2--header-se--sign-in-for-apc .sign-in-menu::before {
  right: 60px;
}

[dir="rtl"] .se2--header-se--sign-in-for-apc .sign-in-menu::before {
  left: 60px;
}

.se2--header-se--sign-in-for-apc .sign-in-menu::before {
  content: '';
  position: absolute;
  top: -5px;
  display: block;
  height: 9px;
  width: 9px;
  box-shadow: 1px -1px 2px 0 rgba(51, 51, 51, 0.14);
  background-color: var(--color-white);
  transform: rotate(-55deg) skewY(22deg);
}

.se2--header-se--sign-in-for-apc.open .sign-in-menu {
  display: flex;
  animation: 0.3s ease-in showList;
}

/* app/components/HeaderSE/partials/skipNavigation/index.scss */
.se2--header-se--skip-navigation > button {
  position: absolute;
  z-index: 100;
  height: 1px;
  width: 1px;
  pointer-events: auto;
  clip: rect(0 0 0 0);
}

[dir="ltr"] .se2--header-se--skip-navigation > button:focus {
  left: 50%;
}

[dir="rtl"] .se2--header-se--skip-navigation > button:focus {
  right: 50%;
}

.se2--header-se--skip-navigation > button:focus {
  top: 0;
  height: auto;
  width: auto;
  border: none;
  border-radius: 0 0 8px 8px;
  padding: 4px 12px;
  margin: 0;
  text-align: center;
  color: var(--color-white);
  background: var(--color-text-gray);
  transform: translate(-50%);
  clip: auto;
}

/* app/components/HeaderSE/partials/stockPrice/index.scss */
.se2--header-se--stock-price {
  display: flex;
  align-items: center;
  width: fit-content;
  text-decoration: none;
}

html .se2--header-se--stock-price {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 1200px) {
  html[dir="ltr"] .se2--header-se--stock-price {
    padding-left: 0;
  }
  html[dir="rtl"] .se2--header-se--stock-price {
    padding-right: 0;
  }
  html[dir="ltr"] .se2--header-se--stock-price {
    padding-right: 10px;
  }
  html[dir="rtl"] .se2--header-se--stock-price {
    padding-left: 10px;
  }
  html .se2--header-se--stock-price {
    padding: 0;
  }
}

.se2--header-se--stock-price svg {
  height: 20px;
  width: 20px;
}

.se2--header-se--stock-price .price-info {
  display: none;
}

[dir="ltr"] .se2--header-se--stock-price .price-icon {
  margin-left: 4px;
}

[dir="rtl"] .se2--header-se--stock-price .price-icon {
  margin-right: 4px;
}

[dir="ltr"] .se2--header-se--stock-price .price-icon {
  margin-right: 10px;
}

[dir="rtl"] .se2--header-se--stock-price .price-icon {
  margin-left: 10px;
}

@media (min-width: 1200px) {
  [dir="ltr"] .se2--header-se--stock-price .price-icon {
    margin-left: 0;
  }
  [dir="rtl"] .se2--header-se--stock-price .price-icon {
    margin-right: 0;
  }
}

.se2--header-se--stock-price .price-icon svg {
  height: 16px;
  width: 7px;
}

[dir="ltr"] .se2--header-se--stock-price .price-diff {
  margin-left: 5px;
}

[dir="rtl"] .se2--header-se--stock-price .price-diff {
  margin-right: 5px;
}

.se2--header-se--stock-price .price-diff {
  font-size: 12px;
}

.se2--header-se--stock-price[data-indicator='down'] svg {
  color: var(--color-fuchsia-red);
  transform: rotate(180deg);
}

.se2--header-se--stock-price[data-indicator='up'] svg {
  color: var(--color-life-green);
}

.se2-accessibility .se2--header-se--stock-price[data-indicator='up'] svg {
  color: var(--color-text-gray);
}

/* app/components/HeaderSE/partials/userMenuForAPC/index.scss */
.se2--header-se--user-menu-for-apc {
  position: relative;
  z-index: 30;
  pointer-events: visible;
}

[dir="ltr"] .se2--header-se--user-menu-for-apc .user-menu {
  right: 0;
}

[dir="rtl"] .se2--header-se--user-menu-for-apc .user-menu {
  left: 0;
}

.se2--header-se--user-menu-for-apc .user-menu {
  position: absolute;
  display: none;
  width: 230px;
  box-shadow: rgba(51, 51, 51, 0.14) 0 0 2px 0, rgba(51, 51, 51, 0.1) 0 7px 15px 0;
  background-color: var(--color-white);
  animation: 0.3s ease-in hideList;
}

.se2--header-se--user-menu-for-apc .user-menu .greeting {
  padding: 15px 15px 10px;
  color: var(--color-light-gray);
}

.se2--header-se--user-menu-for-apc .user-menu a {
  display: block;
  padding: 5px 15px;
  text-decoration: none;
  color: var(--color-text-gray);
  background: none;
}

.se2--header-se--user-menu-for-apc .user-menu a:hover {
  color: var(--color-sky-blue);
  background-color: var(--color-bg-gray-header);
}

.se2--header-se--user-menu-for-apc .user-menu a.logout-link {
  border-top: 1px solid var(--color-footer-gray);
  padding: 10px 15px;
  color: var(--color-sky-blue);
}

.se2--header-se--user-menu-for-apc .user-menu a.logout-link:hover {
  text-decoration: underline;
}

[dir="ltr"] .se2--header-se--user-menu-for-apc .user-menu::before {
  right: 60px;
}

[dir="rtl"] .se2--header-se--user-menu-for-apc .user-menu::before {
  left: 60px;
}

.se2--header-se--user-menu-for-apc .user-menu::before {
  content: '';
  position: absolute;
  top: -5px;
  display: block;
  height: 9px;
  width: 9px;
  box-shadow: 1px -1px 2px 0 rgba(51, 51, 51, 0.14);
  background-color: var(--color-white);
  transform: rotate(-55deg) skewY(22deg);
}

.se2--header-se--user-menu-for-apc.open .user-menu {
  display: block;
  animation: 0.3s ease-in showList;
}

/* app/components/HeaderSE/partials/loginBanner/index.scss */
/* app/stylesheets/mixins/stretchToFullWidth.scss */
/**
 * Mixin stretches element to whole available width of the container
 *
 *  @param {number} $block-start
 *  @param {number} $inline-end
 *  @param {number} $block-end
 *  @param {number} $inline-start
 *  @param {string} $position
 *
 *  Example:
 *    @include stretch-to-full-width();
 *    @include stretch-to-full-width($position: static, $block-start: unset);
 *    @include stretch-to-full-width($block-start: 100px, $inline-end: 200px, $block-end: 30px, $inline-start: 60px);
 *
 */
.se2--header-se--login-banner {
  position: relative;
  z-index: 30;
  pointer-events: visible;
}

[dir="ltr"] .se2--header-se--login-banner .sign-in-menu {
  right: 0;
}

[dir="rtl"] .se2--header-se--login-banner .sign-in-menu {
  left: 0;
}

.se2--header-se--login-banner .sign-in-menu {
  position: absolute;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 350px;
  padding: 30px;
  box-shadow: rgba(51, 51, 51, 0.14) 0 0 2px 0, rgba(51, 51, 51, 0.1) 0 7px 15px 0;
  background-color: var(--color-white);
  animation: 0.3s ease-in hideList;
}

.se2--header-se--login-banner .sign-in-menu h2 {
  font-weight: bold;
  font-size: 18px;
}

.se2--header-se--login-banner .sign-in-menu .description {
  width: 100%;
  margin-top: 0;
  margin-bottom: var(--content-padding-block);
  margin-bottom: 10px;
  color: var(--color-dark-gray);
}

.se2--header-se--login-banner .sign-in-menu .description p,
.se2--header-se--login-banner .sign-in-menu .description ul,
.se2--header-se--login-banner .sign-in-menu .description ol {
  margin-top: 20px;
  margin-bottom: 20px;
}

.se2--header-se--login-banner .sign-in-menu .description p:first-child,
.se2--header-se--login-banner .sign-in-menu .description ul:first-child,
.se2--header-se--login-banner .sign-in-menu .description ol:first-child {
  margin-bottom: 0;
}

.se2--header-se--login-banner .sign-in-menu .description p:last-child,
.se2--header-se--login-banner .sign-in-menu .description ul:last-child,
.se2--header-se--login-banner .sign-in-menu .description ol:last-child {
  margin-bottom: 0;
}

.se2--header-se--login-banner .sign-in-menu .description li {
  justify-content: left;
  align-items: center;
  list-style-type: disc;
  padding-left: 5px;
  padding-right: 5px;
  margin: 10px 0 10px 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.se2--header-se--login-banner .sign-in-menu .login-register-menu {
  display: flex;
  flex-wrap: wrap;
}

.se2--header-se--login-banner .sign-in-menu .login-register-menu a {
  display: inline-block;
  padding-top: 10px;
}

.se2--header-se--login-banner .sign-in-menu .login-menu-primary-button {
  justify-content: left;
  align-items: center;
  height: 40px;
  border-radius: 2px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 10px 30px 10px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  color: var(--color-white);
  background-color: var(--color-sky-blue);
}

.se2--header-se--login-banner .sign-in-menu .login-menu-primary-button:hover {
  background-color: var(--color-sky-blue-light);
}

.se2--header-se--login-banner .sign-in-menu .login-menu-secondary-link,
.se2--header-se--login-banner .sign-in-menu .login-menu-tertiary-link {
  justify-content: left;
  align-items: center;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 10px 0 -10px -10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

[dir="ltr"] .se2--header-se--login-banner .sign-in-menu::before {
  right: 60px;
}

[dir="rtl"] .se2--header-se--login-banner .sign-in-menu::before {
  left: 60px;
}

.se2--header-se--login-banner .sign-in-menu::before {
  content: '';
  position: absolute;
  top: -5px;
  display: block;
  height: 8px;
  width: 8px;
  box-shadow: 1px -1px 2px 0 rgba(51, 51, 51, 0.14);
  background-color: var(--color-white);
  transform: rotate(-55deg) skewY(22deg);
}

.se2--header-se--login-banner.open .sign-in-menu {
  display: flex;
  animation: 0.3s ease-in showList;
}

@keyframes hideList {
  0% {
    max-height: 1000px;
    overflow: hidden;
  }
  100% {
    max-height: 0;
  }
}

@keyframes showList {
  0% {
    max-height: 0;
    overflow: hidden;
  }
  100% {
    max-height: 1000px;
  }
}

[dir="ltr"] .se2--header-se {
  left: 0;
}

[dir="rtl"] .se2--header-se {
  right: 0;
}

.se2--header-se {
  --color-bg-gray-new: #f3f2f2;
  --color-black: #000;
  --color-life-green: #3dcd58;
  --color-white: #fff;
  --color-text-gray: #333;
  --color-sky-blue: #42b4e6;
  position: fixed;
  top: 0;
  z-index: 60;
  width: 100vw;
  pointer-events: none;
}

.se2--header-se .visuallyhidden {
  position: absolute;
  height: 1px;
  width: 1px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.se2--header-se .hide {
  display: none !important;
}

.se2--header-se li {
  list-style: none;
}

.se2--header-se .se2-grey-blue-link {
  text-decoration: none;
  color: var(--color-text-gray);
}

.se2--header-se .size20x20 {
  height: 20px;
  width: 20px;
}

.se2--header-se.expanded-menu {
  z-index: 65;
}

.se2--header-se_header-placeholder {
  height: var(--header-height-mobile);
  min-height: 121px;
}

@media (min-width: 1200px) {
  .se2--header-se_header-placeholder {
    height: var(--header-height-desktop);
    min-height: 152px;
  }
}

.se2--header-se.transparent ~ .se2--header-se_header-placeholder {
  --header-height-desktop: 40px;
}

@media (min-width: 1200px) {
  .se2--header-se.transparent ~ .se2--header-se_header-placeholder {
    height: var(--metabar-height);
    min-height: initial;
  }
}

@media (min-width: 1200px) {
  .se2--header-se.transparent ~ main > .se2--banner-full-image-carousel,
  .se2--header-se.transparent ~ main > .se2--banner-full-image-carousel .fixed-background,
  .se2--header-se.transparent ~ main > .se2--banner-full-image-carousel .fixed-background img,
  .se2--header-se.transparent ~ main > .se2--banner-full-image-carousel .fixed-background video,
  .se2--header-se.transparent ~ main > .se2--banner-full-image-carousel .fixed-background::after {
    height: calc(var(--window-height-inner) - var(--metabar-height));
  }
}

.se2--header-se:not(.transparent) ~ main > [class^='se2--']:not(.se2--banner-full-image-carousel):not(.se2--navigation-customer-journey-with-cta):not(.se2--content-small-square-image):not(.se2--navigation-customer-journey) {
  margin-top: var(--component-block-indent);
}

.se2--header-se:not(.transparent) ~ main > [class^='se2--']:not(.se2--banner-full-image-carousel):not(.se2--navigation-customer-journey-with-cta):not(.se2--content-small-square-image):not(.se2--navigation-customer-journey) ~ [class^='se2--'] {
  margin-top: 0;
}

