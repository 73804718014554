
/* app/components/HeaderSE/partials/megaMenu/index.scss */

.se2--header-se--mega-menu {
  & {
  display: flex;

  &.expanded {
    flex-direction: column;

    .navbar {
      li {
        .menu-nav-button {
          &:not(.hide) {
            display: flex;
            block-size: 63px;
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;

            @media #{breakpoint('xl')} {
              display: unset;
              block-size: unset;
            }
          }

          &.se2-icon-arrow-common::after {
            content: '';
          }

          &.open {
            padding-inline-start: 20px;
            padding-inline-end: 54px;
            font-size: 14px;

            &.se2-icon-arrow-common::before {
              content: '\e802';
              display: inline-block;
              vertical-align: middle;
              inline-size: 22px;
              margin-inline-end: 5px;
              font-family: 'seCustomIconFont', sans-serif;
              font-weight: normal;
              font-style: normal;

              /* Font smoothing. That was taken from TWBS */
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              font-variant: normal;
              font-size: 220%;
              line-height: 14px;
              text-align: center;
              text-transform: none;
              color: var(--color-light-gray);
              transform: rotate(180deg);
              cursor: default;
              speak: never;
            }
          }
        }
      }

      @media #{breakpoint('xl')} {
        margin-block-start: unset;
      }
    }
  }

  .navbar {
    inline-size: 100%;

    @media #{breakpoint('xl')} {
      inline-size: unset;
      padding-block-start: 20px;
      padding-block-end: 20px;
      background-color: var(--color-bg-gray-header);
    }

    li {
      @media #{breakpoint('xl')} {
        inline-size: 300px;
      }

      .menu-nav-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        inline-size: 100%;
        border: none;
        border-block-end: 1px solid var(--color-footer-gray);
        padding-block: 20px;
        padding-inline-start: 20px;
        padding-inline-end: 24px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: start;
        color: var(--text-gray);
        background: none;
        transition: background-color 0.1s linear 0.1s;
        cursor: pointer;

        &.se2-icon-arrow-common::after {
          inline-size: 20px;
          font-size: 150%;
          line-height: 10px;
          color: var(--color-light-gray);
        }

        &.open {
          text-align: center;
        }

        @media #{breakpoint('xl')} {
          border-block-end: none;
          padding-block: 10px;
          padding-inline-start: 40px;
          padding-inline-end: 15px;
          font-weight: 700;

          &:hover,
          &.active {
            color: var(--color-sky-blue);

            .se2-accessibility & {
              color: var(--color-blue);
            }
          }

          &.active {
            background: var(--color-white);
          }

          &.open {
            padding-inline-end: 15px;
            text-align: center;
          }
        }

        svg {
          flex-shrink: 0;
          margin-inline-start: 20px;
          color: var(--color-light-gray);

          @media #{breakpoint('xl')} {
            block-size: 10px;
            inline-size: 10px;
          }
        }
      }
    }
  }

  .submenu {
    position: relative;
    flex-grow: 1;
    inline-size: auto;

    @media #{breakpoint('xl')} {
      display: unset;
    }

    .menu-item {
      inset-inline-start: unset;
      block-size: 100%;
      inline-size: 100%;
      background-color: var(--color-white);

      @media #{breakpoint('xl')} {
        padding-block-start: 30px;
        padding-block-end: 30px;
        padding-inline-start: 40px;
        padding-inline-end: 40px;
      }

      .head {
        @media #{breakpoint('xl')} {
          margin-block-end: 20px;
        }

        .mm-see-all-title-wrapper {
          inline-size: 100%;
        }
      }

      .level2-list {
        display: flex;
        flex-wrap: wrap;

        @media #{breakpoint('xl')} {
          .header-apc & {
            display: block;
            columns: 4 246px;
            column-gap: 20px;
          }
        }

        .level2-item {
          inline-size: 100%;

          .mm-l2-item {
            border-block-end: 1px solid var(--color-footer-gray);

            @media #{breakpoint('xl')} {
              border-block-end: none;
            }

            .mm-l2-button {
              &.mobile {
                font-weight: 700;

                svg {
                  display: none;
                }
              }
            }

            .mm-l3-list {
              .mm-l2-title {
                &.show {
                  border-block-end: 1px solid var(--color-footer-gray);
                  padding-block-start: 20px;
                  padding-block-end: 20px;
                  padding-inline-start: 20px;
                  padding-inline-end: 15px;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 20px;
                  color: var(--color-text-gray);
                }
              }
            }
          }

          @media #{breakpoint('xl')} {
            inline-size: 246px;
            margin-block-end: 20px;
            margin-inline-end: 10px;

            .header-apc & {
              block-size: fit-content;
              inline-size: 100%;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

}
