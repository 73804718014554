
/* app/components/HeaderSE/partials/metabarLink/index.scss */
@import 'app/stylesheets/mixins/stretchToFullWidth';
@import 'app/stylesheets/mixins/visuallyhidden';

.se2--header-se--metabar-link {
  

& {
  html & {
    display: flex;
    align-items: center;
    inline-size: fit-content;
    padding-block-start: 10px;
    padding-block-end: 10px;

    @media #{breakpoint('xl')} {
      order: unset;
      padding-block-start: 0;
      padding-block-end: 0;
      padding-inline-start: 10px;
      padding-inline-end: 10px;

      &:last-of-type {
        padding-inline-end: 0;
      }

      &:hover {
        text-decoration: none;
        color: var(--color-sky-blue);
      }
    }
  }

  svg {
    block-size: 20px;
    inline-size: 20px;

    &.openInNewWindow:dir(rtl) {
      transform: rotate(270deg);
    }
  }

  .icon-wrapper {
    position: relative;
    display: flex;
    margin-inline-end: 5px;

    &.favorite-docs {
      &.se2-icon-doc {
        block-size: 20px;
        margin-inline-start: 3px;
        margin-inline-end: 6px;

        &::before {
          font-size: 147%;
          line-height: normal;
        }
      }
    }
  }

  .label-wrapper {
    .se2-icon-new-tab::after {
      font-size: 145%;
    }
  }

  [data-count='0'] .cart-count {
    @extend %visuallyhidden;
  }

  [data-count] .cart-count {
    content: attr(data-count);
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: calc(100% - 3px);
    min-inline-size: 15px;
    border-radius: 10px;
    padding-inline-start: 2px;
    padding-inline-end: 2px;
    margin-inline-start: -50%;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: var(--color-bg-gray);
    background-color: var(--color-sky-blue);

    @media #{breakpoint('xl')} {
      inset-inline-start: 100%;
      min-inline-size: 20px;
      padding-block-start: 2px;
      padding-block-end: 2px;
      padding-inline-start: 3px;
      padding-inline-end: 3px;
      font-size: 12px;
    }

    &:empty {
      display: none;
    }
  }
}

}
