
/* app/components/HeaderSE/partials/stockPrice/index.scss */

.se2--header-se--stock-price {
  & {
  html & {
    padding-block-start: 10px;
    padding-block-end: 10px;

    @media #{breakpoint('xl')} {
      padding: 0;
      padding-inline-start: 0;
      padding-inline-end: 10px;
    }
  }

  display: flex;
  align-items: center;
  inline-size: fit-content;
  text-decoration: none;

  svg {
    block-size: 20px;
    inline-size: 20px;
  }

  .price {
    &-info {
      // need to hide element while we get information from server
      display: none;
    }

    &-icon {
      margin-inline-start: 4px;
      margin-inline-end: 10px;

      @media #{breakpoint('xl')} {
        margin-inline-start: 0;
      }

      svg {
        block-size: 16px;
        inline-size: 7px;
      }
    }

    &-diff {
      margin-inline-start: 5px;
      font-size: 12px;
    }
  }

  &[data-indicator='down'] svg {
    color: var(--color-fuchsia-red);
    transform: rotate(180deg);
  }

  &[data-indicator='up'] svg {
    color: var(--color-life-green);

    .se2-accessibility & {
      color: var(--color-text-gray);
    }
  }
}

}
