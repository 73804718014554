
/* app/components/HeaderSE/partials/commonPart/index.scss */
@import 'app/stylesheets/mixins/stretchToFullWidth';

.se2--header-se--common-part {
  

& {
  --logo-max-inline-size: 272px;
  --logo-brand-max-block-size: 44px;
  --page-content-sorrounding-gap: 16px;

  @media #{breakpoint('xl')} {
    --page-content-sorrounding-gap: 40px;
  }

  .branded-header & {
    --logo-max-inline-size: 188px; // width of Brand logo by design
  }

  html & {
    @media #{breakpoint('xl')} {
      padding-inline-start: var(--page-content-sorrounding-gap);
      padding-inline-end: var(--page-content-sorrounding-gap);
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 2px 0 rgba(51, 51, 51, 0.05), 0 2px 10px 0 rgba(51, 51, 51, 0.15);
  background: var(--color-white);
  transition: background-color 0.5s ease-out;
  // Due to Header has pointer-events: none
  pointer-events: auto;

  @media #{breakpoint('xl')} {
    flex-direction: row;

    .transparent & {
      box-shadow: unset;
      background: linear-gradient(180deg, var(--color-black) 40%, rgba(171, 171, 171, 0) 100%);

      .se2-accessibility & {
        box-shadow: 0 0 2px 0 rgba(51, 51, 51, 0.05), 0 2px 10px 0 rgba(51, 51, 51, 0.15);
        background: var(--color-white);
      }
    }

    .reduced.transparent & {
      box-shadow: 0 0 2px 0 rgba(51, 51, 51, 0.05), 0 2px 10px 0 rgba(51, 51, 51, 0.15);
      background: var(--color-white);
    }

    &.with-right-side-logo {
      --tab-additional-horizontal-offset: 237px; // width with left margin of right side logo

      justify-content: start;

      .search-menu-wrapper {
        inline-size: 65%;
      }

      .sustainability-logo-link {
        display: block;
        max-block-size: 60px;
        margin-block-start: 16px;
        margin-inline-start: 50px;

        // Intermediate breakpoint to fix Sustainability Logo from disappearing between 1200 and 1360px
        @media only screen and (min-width: 1200px) and (max-width: 1360px) {
          margin-inline-start: 25px;
        }

        &.simplified {
          align-self: center;
          margin-block-start: 0;
        }

        img {
          block-size: 54px;
          inline-size: 177px;
          transition: filter 0.5s;

          // Intermediate breakpoint to fix Sustainability Logo from disappearing between 1200 and 1360px
          @media only screen and (min-width: 1200px) and (max-width: 1360px) {
            block-size: 35px;
            inline-size: 118px;
          }
        }

        .transparent:not(.reduced) &.inverted-logo {
          img {
            filter: invert(1) brightness(2);

            .se2-accessibility & {
              filter: invert(0);
            }
          }
        }
      }

      .mm-tab {
        inline-size: calc(100% + var(--tab-horizontal-offset) + var(--tab-additional-horizontal-offset));
      }
    }
  }

  .mm-l1-button-popup-flexible {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .logo-se-link {
    display: flex;

    &.disabled {
      cursor: default;
      pointer-events: none;
    }

    &.branding {
      position: absolute;
      inset-inline-start: 15px;

      @media #{breakpoint('xl')} {
        inset-block-start: -40px;
        z-index: #{z('one')};
      }
    }

    .logo-standalone {
      max-block-size: 60px;
      padding: 16px;
      background: var(--color-life-green);

      @media #{breakpoint('xl')} {
        max-block-size: 90px;
        padding: 18px;

        &:hover {
          opacity: 0.7;
        }
      }

      img {
        block-size: auto;
        inline-size: 134px;
        aspect-ratio: attr(width) / attr(height);

        @media #{breakpoint('xl')} {
          inline-size: 236px;
        }

        // Intermediate breakpoint to fix Sustainability Logo from disappearing between 1200 and 1360px
        @media only screen and (min-width: 1200px) and (max-width: 1360px) {
          inline-size: 191px;
        }
      }
    }

    .logo-se-brand {
      max-block-size: 28px;
      padding: 4px 8px;
      background: var(--color-life-green);

      @media #{breakpoint('xl')} {
        max-block-size: 90px;
        padding: 8px 16px;

        &:hover {
          opacity: 0.7;
        }
      }

      img {
        block-size: auto;
        inline-size: 64px;
        aspect-ratio: attr(width) / attr(height);

        @media #{breakpoint('xl')} {
          block-size: 32px;
          inline-size: 156px;
        }
      }
    }
  }

  .logo-brand-link {
    position: absolute;
    inset-block-start: 28px;
    inset-inline-start: 0;

    .branded-header & {
      inset-inline-start: 15px;
    }

    @media #{breakpoint('xl')} {
      inset-block-start: -16px;
      display: flex;
      justify-content: center;
      inline-size: var(--logo-max-inline-size);
      background: var(--color-white);
    }
  }

  .logo-brand {
    max-block-size: 60px;
    padding: 6px 0;

    .sdl-media-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      max-block-size: var(--logo-brand-max-block-size);
    }

    &.logo-apc {
      padding-block-end: 0;

      @media #{breakpoint('xl')} {
        padding-block-end: 12px;
      }

      img {
        max-block-size: calc(var(--logo-brand-max-block-size) / 2);
        aspect-ratio: attr(width) / attr(height);

        @media #{breakpoint('xl')} {
          max-block-size: var(--logo-brand-max-block-size);
        }
      }
    }

    @media #{breakpoint('xl')} {
      max-block-size: 90px;
      padding: 40px 0 12px;

      &:hover {
        opacity: 0.7;
      }
    }

    img {
      block-size: auto;
      max-block-size: calc(var(--logo-brand-max-block-size) / 2);
      inline-size: 80px;
      aspect-ratio: attr(width) / attr(height);

      @media #{breakpoint('xl')} {
        max-block-size: var(--logo-brand-max-block-size);
        inline-size: 140px;
      }
    }
  }

  .mobile-block-wrap {
    display: flex;
    justify-content: space-between;
    block-size: 60px;

    .branded-header & {
      justify-content: flex-end;
    }

    @media #{breakpoint('xl')} {
      position: relative;
      display: block;
      block-size: auto;
      min-inline-size: var(--logo-max-inline-size); // inline-size of logo block
    }
  }

  .mobile-block {
    display: flex;
    align-items: center;
    margin-inline-end: 12px;

    @media #{breakpoint('xl')} {
      display: none;
    }

    .btn-nav {
      display: flex;
      justify-content: center;
      align-items: center;
      block-size: 36px;
      inline-size: 36px;
      border: 0;
      padding: 0;
      color: var(--color-text-gray);
      background: transparent;
      opacity: 0.7;
      cursor: pointer;

      [data-count='0'] .cart-count {
        display: none;
      }
    }

    .btn-nav + .btn-nav {
      margin-inline-start: 4px;
    }
  }

  .search-menu-wrapper {
    inline-size: 100%;

    @media #{breakpoint('xl')} {
      margin-block-start: 12px;
      margin-inline-start: 30px;

      &.menu-wrapper-simplified {
        margin-block-start: 0;
      }
    }

    // Intermediate breakpoint to fix Sustainability Logo from disappearing between 1200 and 1360px
    @media only screen and (min-width: 1200px) and (max-width: 1360px) {
      min-inline-size: inherit;
    }
  }

  .sustainability-logo-link {
    display: none;
  }

  .menu-wrapper-apc {
    position: absolute;

    @media #{breakpoint('xl')} {
      position: static;
    }
  }

  .menu-wrapper-simplified {
    .mm-wrap {
      .mm-nav,
      .mm-l1-list {
        @media #{breakpoint('xl')} {
          height: 100%;
        }
      }
    }
  }

  .mm-wrap {
    @include stretch-to-full-width();

    .mm-nav {
      margin-block-start: 60px;
    }

    .sustainability-logo-link {
      display: block;
      inline-size: fit-content;
      padding-top: 10px;
      padding-bottom: 10px;

      img {
        block-size: 50px;
        inline-size: 160px;
        min-inline-size: initial;
      }
    }

    block-size: var(--window-height-inner);
    overflow-y: scroll;
    background-color: var(--color-white);

    &.close {
      display: none;
    }

    @media #{breakpoint('xl')} {
      position: initial;
      display: initial;
      block-size: 100%;

      .mob-b2bB2cToggle {
        display: none;
      }

      &.close {
        display: initial;
      }

      .mm-nav {
        margin-block-start: 0;
      }
    }

    &.open {
      .mm-l1-list {
        border-block-start: 0;
      }

      .mm-nav {
        margin-block-start: 0;
      }

      .mm-l1-item:not(.open):not(.close) {
        display: none;

        @media #{breakpoint('xl')} {
          display: block;
        }
      }
    }
  }

  .mobile-menu-btn-close {
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
    z-index: #{z('default')};
    border: 0;
    padding-block-start: 22px;
    padding-block-end: 22px;
    padding-inline-start: 16px;
    padding-inline-end: 24px;
    background: none;
    cursor: pointer;

    @media #{breakpoint('xl')} {
      display: none;
    }

    &.se2-icon-close::before {
      font-size: 120%;
      color: var(--color-light-gray);
    }
  }

  // TODO: move style to each partial
  .mm-l1-list {
    display: flex;
    flex-direction: column;
    border-block-start: 1px solid var(--color-footer-gray);

    @media #{breakpoint('xl')} {
      position: relative;
      flex-direction: row;
      border-block-start: 0;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }

  .mm-l1-item {
    &-link {
      text-decoration: none;
    }

    &.close {
      display: none;
    }

    &.open {
      display: block;

      .mm-l1-section-back-chevron {
        display: block;
      }

      .mm-l1-section-name-chevron {
        display: none;
      }

      .mm-l1-section-name {
        justify-content: center;
        padding-block-start: 20px;
        padding-block-end: 20px;
      }

      & .mm-l1-button-popup {
        display: grid;
        grid-template-columns: 10% 78% 12%;
        padding-inline-start: 20px;

        @media #{breakpoint('xl')} {
          display: flex;
          justify-content: flex-start;
          justify-content: inherit;
        }

        &.close {
          display: none;

          @media #{breakpoint('xl')} {
            display: block;
          }
        }

        & span {
          display: flex;
          justify-content: flex-end;
        }

        & .mm-l1-section-name-chevron {
          display: none;

          @media #{breakpoint('xl')} {
            display: block;

            &.se2-icon-arrow-common::after {
              &:dir(rtl) {
                transform: rotate(270deg);
              }
            }
          }
        }

        & .mm-l1-section-back-chevron {
          @media #{breakpoint('xl')} {
            display: none;
          }
        }
      }
    }

    @media #{breakpoint('xl')} {
      &.close {
        display: block;
      }

      &.open {
        .mm-l1-button-popup {
          padding-inline-start: 0;

          &::after {
            opacity: 1;
          }
        }

        .mm-l1-section-back-chevron {
          display: none;
        }

        .mm-l1-section-name-chevron {
          display: block;

          &.se2-icon-arrow-common::after {
            inline-size: 20px;
            font-size: 120%;
            line-height: 20px;
            transform: rotate(-90deg);
          }
        }
      }

      margin-inline-end: 36px;

      &:last-child {
        margin-inline-end: 0;
      }
    }
  }

  .mm-l1-button-popup {
    block-size: 100%;
  }

  .mm-l1-item-link {
    block-size: auto;
  }

  .mm-l1-button-popup,
  .mm-l1-item-link {
    position: relative;
    display: flex;
    align-items: center;
    inline-size: 100%;
    border: 0;
    border-block-end: 1px solid var(--color-footer-gray);
    padding-inline-start: 30px;
    padding-inline-end: 12px;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    background: transparent;
    cursor: pointer;

    @media #{breakpoint('xl')} {
      border-block-end: 0;
      padding: 0;
      padding-inline-start: 0;
      padding-inline-end: 0;
      text-transform: none;

      &::after {
        content: '';
        position: absolute;
        inset-block-end: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        z-index: #{z('above')};
        border-block-end: 4px solid var(--color-life-green);
        opacity: 0;
        transition: opacity linear 0.3s;
      }

      &:hover::after {
        opacity: 1;
      }
    }

    .mm-l1-section-back-chevron {
      display: none;
      transform: rotate(-180deg);

      &.se2-icon-arrow-common::after {
        inline-size: 22px;
      }

      @media #{breakpoint('xl')} {
        display: none;
      }
    }

    .mm-l1-section-name-chevron {
      &.se2-icon-arrow-common::after {
        inline-size: 40px;
      }
    }

    .mm-l1-section-name-chevron,
    .mm-l1-section-back-chevron {
      //block-size: 14px;
      color: var(--color-light-gray);

      @media #{breakpoint('xl')} {
        block-size: 20px;
        inline-size: 20px;
        margin-inline-end: 0;
      }

      &.se2-icon-arrow-common::after {
        font-size: 170%;
        line-height: 14px;

        &:dir(rtl) {
          transform: rotate(180deg);
        }

        @media #{breakpoint('xl')} {
          inline-size: 20px;
          font-size: 120%;
          line-height: 20px;
          transform: rotate(90deg);

          &:dir(rtl) {
            transform: rotate(90deg);
          }

          .transparent & {
            color: var(--color-white);

            .se2-accessibility & {
              color: var(--color-light-gray);
            }
          }

          .reduced.transparent & {
            color: var(--color-light-gray);
          }
        }
      }
    }

    &.close {
      display: none;
    }
  }

  .mm-l1-section-name {
    position: relative;
    display: flex;
    inline-size: 100%;
    padding-block-start: 20px;
    padding-block-end: 20px;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: var(--color-text-gray);
    transition: font-size 0.25s linear;

    @media #{breakpoint('xl')} {
      padding-block-start: 20px;
      padding-block-end: 20px;
      margin-inline-end: 0;

      .transparent & {
        color: var(--color-white);

        .se2-accessibility & {
          color: var(--color-black);
        }
      }

      .header-brand-simplified & {
        padding-block: 30px;
      }

      .reduced.transparent & {
        color: var(--color-black);
      }
    }
  }

  .mm-l1-item.open .mm-l1-section-name {
    margin-inline-end: 36px;

    @media #{breakpoint('xl')} {
      margin-inline-end: 0;
    }
  }

  .mm-tab {
    &.stubbed-content {
      min-block-size: 90px;
    }

    &.close {
      display: none;
    }

    .white-background {
      background-color: var(--color-white);
    }

    @media #{breakpoint('xl')} {
      position: absolute;
      inset-block-start: var(--tab-vertical-offset);
      inset-inline-start: calc(-1 * var(--tab-horizontal-offset));
      max-block-size: calc(100vh - var(--header-height-possible-max));
      inline-size: calc(100% + var(--tab-horizontal-offset)); // to align megamenu by design
      border-end-start-radius: 2px;
      border-end-end-radius: 2px;
      overflow-y: auto;
      box-shadow: 0 0 2px 0 rgba(51, 51, 51, 0.5), 0 7px 15px 0 rgba(51, 51, 51, 0.5);
      background: var(--color-bg-gray);
    }
  }

  .mm-main-list-wrap {
    @media #{breakpoint('xl')} {
      display: flex;
      padding-block-start: 15px;
      padding-block-end: 15px;
      padding-inline-start: 40px;
      padding-inline-end: 40px;
    }
  }

  .mm-cta-wrap {
    display: flex;
    flex-direction: column;

    &.close {
      display: none;
    }

    @media #{breakpoint('xl')} {
      flex-direction: row;
      margin-block-end: 15px;
    }
  }

  .mm-see-all-title {
    inline-size: 100%;
    border-block-end: 1px solid var(--color-footer-gray);
    padding-block-start: 20px;
    padding-block-end: 20px;
    padding-inline-start: 20px;
    padding-inline-end: 15px;
    font-weight: 700;

    svg {
      block-size: 15px;
      inline-size: 15px;

      &:dir(rtl) {
        transform: rotate(180deg);
      }

      &.openInNewWindow:dir(rtl) {
        transform: rotate(270deg);
      }
    }

    &-wrapper {
      display: flex;
    }

    @media #{breakpoint('xl')} {
      inline-size: fit-content;
      border-block-end: 0;
      padding-block-start: 0;
      padding-block-end: 0;
      padding-inline-start: 10px;
      padding-inline-end: 10px;

      svg {
        display: initial;
        block-size: 25px;
        inline-size: 25px;
      }

      &-wrapper {
        inline-size: 25%;
      }
    }
  }

  .mm-cta {
    @media #{breakpoint('xl')} {
      min-inline-size: 50%;
      max-inline-size: 75%;
    }

    .mm-cta-link {
      position: relative;
      display: flex;
      justify-content: center;
      border-block-end: 1px solid var(--color-footer-gray);
      padding-block-start: 5px;
      padding-block-end: 5px;
      padding-inline-start: 40px;
      padding-inline-end: 40px;
      text-decoration: none;

      @media #{breakpoint('xl')} {
        border: 1px solid var(--color-footer-gray);
      }

      &:hover p {
        text-decoration: underline;

        span .nowrap {
          text-decoration: underline;
        }
      }
    }

    .mm-cta-content-title {
      color: var(--color-dark-gray);
    }

    .mm-cta-content {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
    }

    .mm-cta-icon {
      @include stretch-to-full-width();

      display: flex;
      justify-content: center;
      align-items: center;
      block-size: 30px;
      inline-size: 30px;
      border-end-end-radius: 100%;
      background: var(--color-sky-blue);

      &.se2-icon-doc::before {
        position: absolute;
        inset-block-start: 5px;
        inset-inline-start: 5px;
        color: var(--color-white);
      }
    }
  }

  .mm-l2-list--top {
    display: none;

    @media #{breakpoint('xl')} {
      display: flex;
      margin-block-end: 15px;
    }
  }

  .mm-l2-list {
    @media #{breakpoint('xl')} {
      column-count: 4;
      column-gap: 0;
    }
  }

  .mm-l2-list-column {
    @media #{breakpoint('xl')} {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      overflow-y: auto; // prevent hyphenation to next column
    }
  }

  .mm-l2-button {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    block-size: 100%;
    inline-size: 100%;
    border: 0;
    border-block-end: 1px solid var(--color-footer-gray);
    padding-block-start: 20px;
    padding-block-end: 20px;
    padding-inline-start: 20px;
    padding-inline-end: 24px;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: var(--color-text-gray);
    background: transparent;
    cursor: pointer;

    @media #{breakpoint('xl')} {
      display: none;
    }

    .mm-l2-section-back-chevron {
      display: none;
      transform: rotate(-180deg);

      @media #{breakpoint('xl')} {
        display: none;
      }

      &.se2-icon-arrow-common::after {
        inline-size: 22px;
        font-size: 220%;
        line-height: 14px;
        color: var(--color-light-gray);

        &:dir(rtl) {
          transform: rotate(0deg);
        }
      }
    }

    .mm-l2-section-name-chevron {
      &.se2-icon-arrow-common::after {
        font-size: 150%;
        line-height: 10px;
      }
    }

    .mm-l2-section-name-chevron,
    .mm-l2-section-back-chevron {
      &.se2-icon-arrow-common::after {
        color: var(--color-light-gray);

        &:dir(rtl) {
          transform: rotate(180deg);
        }
      }
    }
  }

  .mm-l2-section-name {
    position: relative;
    display: flex;
    inline-size: 100%;
    text-align: start;
    color: var(--color-text-gray);
    transition: font-size 0.25s linear;

    @media #{breakpoint('xl')} {
      display: inline-block;
    }
  }

  .mm-l2-item.open .mm-l2-section-name {
    margin-inline-end: 30px;

    @media #{breakpoint('xl')} {
      margin-inline-end: 0;
    }
  }

  .mm-l2-item.open .mm-l2-section-name {
    text-align: center;
  }

  .mm-l2-item.open .mm-l2-button {
    display: grid;
    grid-template-columns: 10% 80% 10%;
    font-weight: 700;
    text-align: center;

    & span {
      display: flex;
      justify-content: flex-end;
    }

    & .mm-l2-section-name-chevron {
      display: none;
    }
  }

  .mm-promo {
    &-img-wrap {
      display: none;
      inline-size: 100%;

      @media #{breakpoint('xl')} {
        display: block;
      }
    }

    &-content-wrap {
      display: none;
      margin-block-end: 20px;

      @media #{breakpoint('xl')} {
        display: block;
      }
    }

    @media #{breakpoint('xl')} {
      flex: 0 0 25%;
      block-size: 100%;

      &-title {
        margin-block-end: 10px;
        font-weight: 700;
        color: var(--color-life-green);

        .se2-accessibility & {
          color: var(--color-text-gray);
        }
      }

      &-description {
        margin-block-end: 10px;
        font-size: 12px;
        line-height: 15px;
      }

      &-link {
        &:hover {
          text-decoration: underline;
        }

        svg {
          block-size: 18px;
          inline-size: 18px;

          &:dir(rtl) {
            transform: rotate(180deg);
          }
        }
      }

      img {
        block-size: 100%;
        inline-size: 100%;
      }
    }
  }

  .mm-promo-cta {
    &:not(:last-child) {
      @media #{breakpoint('xl')} {
        margin-block-end: 10px;
      }
    }

    &.close {
      display: none;

      @media #{breakpoint('xl')} {
        display: block;
      }
    }

    &-link {
      position: relative;
      display: flex;
      justify-content: center;
      border: 1px solid var(--color-footer-gray);
      padding: 15px 30px;
      text-decoration: none;

      .mm-promo-cta-content-description {
        font-weight: normal;
      }

      &:hover {
        .mm-promo-cta-content-description {
          text-decoration: underline;
        }
      }
    }

    &-icon {
      @include stretch-to-full-width();

      display: flex;
      justify-content: center;
      align-items: center;
      block-size: 30px;
      inline-size: 30px;
      border-end-end-radius: 100%;
      background: var(--color-sky-blue);

      svg {
        block-size: 15px;
        inline-size: 15px;
        margin-block-start: -5px;
        margin-inline-start: -5px;
        color: var(--color-white);

        &:dir(rtl) {
          transform: rotate(180deg);
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      line-height: 20px;

      &-title {
        margin-inline-end: 0;
        font-weight: 700;
        text-align: center;
        color: var(--color-dark-gray);
      }

      &-description {
        margin-block-start: 5px;

        svg {
          block-size: 18px;
          inline-size: 18px;

          &:dir(rtl) {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .mm-main-list,
  .mm-main-list-products {
    display: flex;
    flex-direction: column;
    inline-size: 100%;

    @media #{breakpoint('xl')} {
      .mm-see-all-title-wrapper {
        .se2-icon-arrow-double::after {
          font-size: 155%;
        }
      }
    }
  }

  .mm-main-list:not(:only-child) {
    @media #{breakpoint('xl')} {
      .mm-main-list {
        flex: 0 0 75%;
      }

      .mm-l2-list {
        column-count: 3;
      }

      .mm-see-all-title-wrapper {
        inline-size: 33%;
      }
    }
  }

  .mm-l2-item {
    &.close {
      display: none;
    }

    &.open {
      .mm-l2-section-name {
        justify-content: center;
        font-weight: 700;
      }

      .mm-l2-section-back-chevron {
        display: block;
        margin-inline-end: 16px;
      }

      .mm-l2-section-name-chevron {
        display: none;
      }
    }

    &-mobile {
      @media #{breakpoint('xl')} {
        display: none;
      }
    }

    @media #{breakpoint('xl')} {
      margin-block-end: 15px;
    }
  }

  .mm-l2-item.extra-links {
    @media #{breakpoint('xl')} {
      display: none;
    }
  }

  .mm-l2-title {
    display: none;

    @media #{breakpoint('xl')} {
      display: initial;
      padding-inline-start: 10px;
      padding-inline-end: 10px;
      margin-block-end: 5px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: var(--color-text-gray);
    }
  }

  .mm-l2-link {
    display: block;
    border-block-end: 1px solid var(--color-footer-gray);
    padding-block-start: 20px;
    padding-block-end: 20px;
    padding-inline-start: 20px;
    padding-inline-end: 16px;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;

    @media #{breakpoint('xl')} {
      .se2-icon-arrow-common::after {
        font-size: 145%;
      }
    }

    svg {
      block-size: 20px;
      inline-size: 20px;

      &:dir(rtl) {
        transform: rotate(180deg);
      }

      &.openInNewWindow:dir(rtl) {
        transform: rotate(270deg);
      }
    }

    @media #{breakpoint('xl')} {
      display: block;
      inline-size: fit-content;
      border-block-end: 0;
      padding-block-start: 0;
      padding-block-end: 0;
      padding-inline-start: 10px;
      padding-inline-end: 10px;
      margin-block-end: 5px;
      font-weight: 700;

      svg.arrow {
        display: initial;
        color: var(--color-text-gray);
      }
    }
  }

  .mm-l2-link.mm-l2-top-mobile-link {
    position: relative;
    background-color: var(--color-bg-gray-new);

    &::after {
      content: '';
      position: absolute;
      inset-block-start: 100%;
      border: 10px solid transparent;
      border-top: 10px solid var(--color-bg-gray-new);
    }

    @media #{breakpoint('xl')} {
      display: none;
    }
  }

  .mm-l3-list {
    display: flex;
    flex-direction: column;

    & .mm-l2-link {
      display: block;
    }

    & .mm-l2-link .se2-icon-arrow-common::after {
      font-size: 143%;
      color: var(--color-light-gray);
    }

    &.close {
      display: none;
    }

    @media #{breakpoint('xl')} {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      overflow-y: hidden; // fix for word wrapping in FF

      & .mm-l2-link .se2-icon-arrow-common::after {
        color: inherit;
      }

      &.close {
        display: flex;
      }
    }
  }

  .mm-l3-link {
    border-block-end: 1px solid var(--color-footer-gray);
    padding-block-start: 20px;
    padding-block-end: 20px;
    padding-inline-start: 20px;
    padding-inline-end: 15px;

    .se2-icon-new-tab::after {
      @media #{breakpoint('xl')} {
        font-size: 150%;
      }
    }

    &:last-child {
      border: none;
    }

    svg {
      display: initial;
      block-size: 18px;
      inline-size: 18px;

      &:dir(rtl) {
        transform: rotate(180deg);
      }

      &.openInNewWindow:dir(rtl) {
        transform: rotate(270deg);
      }
    }

    @media #{breakpoint('xl')} {
      inline-size: fit-content;
      border-block-end: 0;
      padding-block-start: 0;
      padding-block-end: 0;
      padding-inline-start: 10px;
      padding-inline-end: 10px;
      font-size: 12px;
      line-height: 18px;

      svg {
        display: initial;
        block-size: 18px;
        inline-size: 18px;
      }
    }
  }

  .mm-extra-list {
    display: none;

    @media #{breakpoint('xl')} {
      display: flex;
    }

    &-label {
      display: none;

      @media #{breakpoint('xl')} {
        display: initial;
      }
    }

    &-mobile {
      @media #{breakpoint('xl')} {
        display: none;
      }
    }

    &-wrap {
      display: flex;
      padding-block-start: 10px;
      padding-block-end: 10px;
      padding-inline-start: 10px;
      padding-inline-end: 10px;
      font-size: 12px;
      line-height: 15px;

      @media #{breakpoint('xl')} {
        border-block-start: 1px solid var(--color-footer-gray);
      }

      .mm-extra-link {
        margin-inline-start: 20px;
        margin-inline-end: 20px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      svg {
        block-size: 15px;
        inline-size: 15px;

        &:dir(rtl) {
          transform: rotate(180deg);
        }
      }
    }
  }

  .mobile-metabar {
    display: flex;
    flex-direction: column;
    padding-block-start: 20px;
    padding-inline-start: 30px;
    padding-inline-end: 10px;

    @media #{breakpoint('xl')} {
      display: none;
    }

    .login {
      &-wrap {
        order: 3;
      }

      &-menu {
        display: none;

        a,
        button {
          padding: 10px 0;
          font-size: 14px;
          line-height: 20px;
        }

        a {
          display: block;
          text-decoration: none;
          color: var(--color-text-gray);

          &:hover {
            color: var(--color-sky-blue);
          }
        }

        button {
          display: flex;
          inline-size: 100%;
          border: none;
          margin: 0;
          background: none;
          cursor: pointer;

          &:hover {
            color: var(--color-sky-blue);
          }
        }
      }
    }

    .login-wrap.open .login-menu {
      display: block;
    }
  }
}

}
