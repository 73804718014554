
/* app/partials/singleLink/index.scss */
@import 'app/stylesheets/mixins/stretchToFullWidth';

.se2--single-link {
  

& {
  display: inline-flex;

  &.stretched-link::after {
    @include stretch-to-full-width;

    content: '';
    display: block;
  }

  &.nowrap-underline:hover span.nowrap {
    text-decoration: underline;
  }
}

}
