
/* app/components/HeaderSE/partials/metabar/index.scss */

.se2--header-se--metabar {
  
& {
  --logo-placeholder-width: 225px;
  --page-content-sorrounding-gap: 16px;

  @media #{breakpoint('xl')} {
    --page-content-sorrounding-gap: 40px;
  }

  html & {
    position: relative;
    display: none;
    justify-content: space-between;
    align-items: center;
    block-size: 40px;
    padding-inline-start: var(--page-content-sorrounding-gap);
    padding-inline-end: var(--page-content-sorrounding-gap);
    font-size: 14px;
    line-height: 20px;
    background-color: var(--color-bg-gray-new);
    // Due to Header has pointer-events: none
    pointer-events: auto;

    @media #{breakpoint('xl')} {
      display: flex;
    }
  }

  header.branded-header & {
    padding-inline-start: calc(var(--page-content-sorrounding-gap) + var(--logo-placeholder-width));
  }

  .site-info,
  .user-list {
    display: flex;
    justify-content: center;
  }

  .site-info {
    block-size: 100%;

    .icon-wrapper {
      display: flex;
    }

    .language-selector-toggler {
      @media #{breakpoint('xl')} {
        padding-inline-start: 0;
        padding-inline-end: 10px;
      }
    }
  }

  .user-list {
    block-size: 100%;

    & button {
      &:first-of-type {
        padding-inline-start: 10px;
        margin-inline-start: 20px;
      }
    }

    .login {
      &-wrap {
        position: relative;
      }

      &-menu {
        position: absolute;
        z-index: #{z('one')};
        display: none;
        padding: 15px;
        background: var(--color-bg-gray);

        &::before {
          // need to create triangle
          content: '';
          position: absolute;
          inset-block-start: 0;
          // 5px is half small diagonal rhombus
          inset-block-end: -5px;
          // 7px is half big diagonal rhombus
          inset-inline-start: calc(50% - 7px);
          z-index: #{z('one')};
          display: block;
          block-size: 9px; // according to design
          inline-size: 9px; // according to design
          // need to have box shadow with negative indent for creating triangle
          box-shadow: 1px -1px 2px 0 rgba(#333, 0.14);
          // TODO check why pseudo elem do not see css variable
          background: var(--color-white);
          opacity: 0;
          // According to the design, the triangle is obtuse,
          // so we need to tilt the square by 22 degrees (112-90=22 where 112 is the size of the obtuse angle) and rotate 55 degrees
          transform: rotate(-55deg) skewY(22deg);
        }

        a,
        button {
          padding: 0 15px;
          margin-bottom: 15px;
        }

        a {
          display: block;
        }

        button {
          display: flex;
          inline-size: 100%;
          border: none;
          margin: 0;
          background: none;
          cursor: pointer;

          &:hover {
            color: var(--color-sky-blue);
          }
        }
      }
    }

    .login-wrap.open .login-menu {
      display: block;

      &::before {
        opacity: 1;
      }
    }
  }
}

}
