
/* app/components/HeaderSE/partials/geolocationBanner/index.scss */

.se2--header-se--geolocation-banner {
  $grid-outer-xs: 15px;
$grid-outer-xl: 10px;
$reserved-btn-place: 50px;
$banner-shadow: 0 2px 5px 0 rgba(33, 33, 33, 0.15);

html & {
  // need to align close button
  position: fixed;
  order: 1; // according to design Selector banner need to be positioned above header in xs
  inline-size: 100%;
  padding: $grid-outer-xs var(--page-content-sorrounding-gap);
  box-shadow: $banner-shadow;
  color: var(--color-dark-gray);
  background: var(--color-white);
  // Due to Header has pointer-events: none
  pointer-events: auto;

  @media #{breakpoint('xl')} {
    z-index: #{z('default')};
    padding: 2 * $grid-outer-xl var(--page-content-sorrounding-gap);
  }

  .head {
    padding-inline-end: $reserved-btn-place;
    margin-block-end: 15px;
    font-size: 25px;
    line-height: 35px;
  }

  & .icon-flag {
    margin-inline-start: 10px;

    @media #{breakpoint('xl')} {
      margin-inline-start: 20px;
    }
  }

  & .icon-flag,
  & .icon-close {
    block-size: 20px;
    inline-size: 20px;
  }

  .body {
    > div {
      margin-block-end: 15px;
    }
  }

  .button-accept {
    margin-block-end: 10px;

    @media #{breakpoint('xl')} {
      margin-inline-end: 15px;
    }
  }

  .svg-ml {
    margin-block-start: 10px;

    @media #{breakpoint('xl')} {
      margin-block-start: 20px;
    }
  }

  .button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    block-size: 40px;
    inline-size: 100%;
    border-radius: 2px;
    padding: 0 20px;
    margin-block-end: 10px;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    @media #{breakpoint('xl')} {
      block-size: 48px;
      inline-size: auto;
      max-inline-size: 100%;
    }

    &:dir(rtl) {
      svg {
        transform: rotateY(180deg);
      }
    }

    .button-text {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
}

}
