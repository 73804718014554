
/* app/components/HeaderSE/deferrals/brandSelector/index.scss */
@import 'app/stylesheets/mixins/stretchToFullWidth.scss';

.se2--header-se--brand-selector {
  

& {
  @include stretch-to-full-width($position: fixed);

  z-index: #{z('fixed')};
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  // Due to Header has pointer-events: none
  pointer-events: auto;

  @media #{breakpoint('xl')} {
    position: unset;
  }

  .list {
    &-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      box-shadow: 0 1px 10px 0 rgba(rgb(51, 51, 51), 0.15);
    }

    &-title {
      margin: 0;
      font-weight: 700;
      color: var(--color-text-gray);
    }

    &-back-btn,
    &-close-btn {
      display: block;
      border: none;
      padding: 0;
      background: transparent;

      &:hover {
        cursor: pointer;

        &.se2-icon-close::before,
        &.se2-icon-arrow-common-wide::after {
          opacity: 0.8;
        }
      }

      &.se2-icon-arrow-common-wide::after,
      &.se2-icon-close::before {
        font-size: 120%;
        color: var(--color-text-gray);
        opacity: 0.5;
      }

      &.se2-icon-arrow-common-wide::after {
        inline-size: 22px;
        font-size: 220%;
        line-height: 15px;
        transform: rotate(180deg);

        &:dir(rtl) {
          transform: rotate(360deg);
        }
      }
    }

    &-back-btn {
      block-size: 18px;
      inline-size: 15px;
    }

    &-close-btn {
      block-size: 18px;
      inline-size: 15px;
    }
  }

  .brand-list {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    block-size: 1px;

    @media #{breakpoint('xl')} {
      position: absolute;
      inset-block-start: 40px;
      z-index: #{z('fixed')};
      display: none;
      flex-grow: unset;
      flex-direction: row;
      flex-wrap: wrap;
      block-size: unset;
      min-block-size: 70px;
      max-block-size: 0;
      min-inline-size: 70px;
      margin: 0;
      overflow: hidden;
      background: var(--color-white);
      animation: 0.3s ease-in hideList;
    }

    &.open {
      display: flex;
      max-block-size: 1000px;
      box-shadow: rgba(6, 11, 45, 0.25) 0 3px 7px 1px;

      @media #{breakpoint('xl')} {
        overflow: inherit;
        animation: 0.3s ease-in showList;
      }
    }

    &[data-mobile-size='xs'] {
      img {
        block-size: 50px;
      }
    }

    &[data-mobile-size='s'] {
      img {
        block-size: 35px;
      }
    }

    &[data-mobile-size='m'] {
      img {
        block-size: 25px;
      }
    }

    &[data-mobile-size='l'] {
      img {
        block-size: 20px;
      }
    }

    @media #{breakpoint('xl')} {
      &[data-desktop-size='s'] {
        inline-size: 240px;

        li {
          inline-size: 50%;
        }
      }

      &[data-desktop-size='m'] {
        inline-size: 360px;

        li {
          inline-size: 120px;
        }
      }

      &[data-desktop-size='l'] {
        inline-size: 480px;

        li {
          inline-size: 25%;
        }
      }
    }

    li {
      position: relative;
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      inline-size: 100%;
      border-block-start: 1px solid var(--color-footer-gray);

      @media #{breakpoint('xl')} {
        display: list-item;
        flex-grow: 0;
        justify-content: unset;
        align-items: unset;
        block-size: 70px;
        border-block-start: unset;
        border-block-end: 1px solid var(--color-footer-gray);
        border-inline-end: 1px solid var(--color-footer-gray);
      }

      &::after {
        @include stretch-to-full-width();

        content: '';
        display: none;
        block-size: 100%;
        inline-size: 3px;
        background: var(--color-life-green);

        @media #{breakpoint('xl')} {
          block-size: 3px;
          inline-size: 100%;
        }
      }

      &:hover,
      &.selected {
        &::after {
          display: block;
        }
      }

      &:hover {
        .visuallyhidden {
          inset-block-start: 50%;
          inset-inline-start: 50%;
          z-index: #{z('fixed')};
          block-size: auto;
          inline-size: auto;
          border: 1px solid var(--color-black);
          padding-block: 0;
          padding-inline: 5px;
          font-weight: 400;
          font-size: 12px;
          white-space: nowrap;
          text-transform: none;
          color: var(--color-black);
          background-color: var(--color-white);
          clip: auto;

          @media #{breakpoint('xl')} {
            inset-block-start: 40px;
            inset-inline-start: 40px;
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        block-size: 100%;
        inline-size: 100%;
        padding-block: 25px;
        padding-inline: 0;

        @media #{breakpoint('xl')} {
          justify-content: center;
          padding: 10px;

          &:last-child {
            padding-inline-end: 10px;
          }
        }

        .sdl-media-wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          block-size: 100%;

          @media #{breakpoint('xl')} {
            text-align: center;
          }

          img {
            max-block-size: 20px;
            min-inline-size: unset;

            @media #{breakpoint('xl')} {
              block-size: auto;
              max-block-size: 20px;
              max-inline-size: 100%;
            }
          }
        }
      }

      &:hover,
      &.selected {
        &::after {
          display: block;
        }
      }
    }
  }
}

}
