
/* app/components/HeaderSE/partials/predictiveSearch/index.scss */

.se2--header-se--predictive-search {
  & {
  html & {
    border-block-start: 1px solid var(--color-footer-gray);
    padding-block: 8px;
    padding-inline: 12px;

    @media #{breakpoint('xl')} {
      border: 0;
      padding: 0;
    }
  }
}

}
