
/* app/components/HeaderSE/partials/userMenuForAPC/index.scss */

.se2--header-se--user-menu-for-apc {
  & {
  position: relative;
  z-index: #{z('dropdown')};
  pointer-events: visible;

  .user-menu {
    position: absolute;
    inset-inline-end: 0;
    display: none;
    inline-size: 230px;
    box-shadow: rgba(51, 51, 51, 0.14) 0 0 2px 0, rgba(51, 51, 51, 0.1) 0 7px 15px 0;
    background-color: var(--color-white);
    animation: 0.3s ease-in hideList;

    .greeting {
      padding: 15px 15px 10px;
      color: var(--color-light-gray);
    }

    a {
      display: block;
      padding: 5px 15px;
      text-decoration: none;
      color: var(--color-text-gray);
      background: none;

      &:hover {
        color: var(--color-sky-blue);
        background-color: var(--color-bg-gray-header);
      }
    }

    a.logout-link {
      border-top: 1px solid var(--color-footer-gray);
      padding: 10px 15px;
      color: var(--color-sky-blue);

      &:hover {
        text-decoration: underline;
      }
    }

    &::before {
      content: '';
      position: absolute;
      inset-block-start: -5px;
      inset-inline-end: 60px;
      display: block;
      block-size: 9px;
      inline-size: 9px;
      box-shadow: 1px -1px 2px 0 rgba(51, 51, 51, 0.14);
      background-color: var(--color-white);
      transform: rotate(-55deg) skewY(22deg);
    }
  }

  &.open {
    .user-menu {
      display: block;
      animation: 0.3s ease-in showList;
    }
  }
}

}
