
/* app/components/HeaderSE/partials/metabarButton/index.scss */

.se2--header-se--metabar-button {
  & {
  display: flex;
  align-items: center;
  block-size: 100%;
  inline-size: fit-content;
  border: 0;
  padding: 0;
  padding-block-start: 10px;
  padding-block-end: 10px;
  text-decoration: none;
  background: none;
  transition: background-color 0.5s;
  cursor: pointer;

  &:first-child {
    padding-inline-start: 0;
  }

  &.se2-grey-blue-link:hover {
    text-decoration: none;
  }

  @media #{breakpoint('xl')} {
    order: unset;
    padding-block-start: 0;
    padding-block-end: 0;
    padding-inline-start: 5px;
    padding-inline-end: 5px;

    &:hover {
      color: var(--color-black);
      background-color: var(--color-footer-gray);
    }
  }

  & > svg {
    margin-inline-end: 5px;
  }

  &.se2-icon-arrow-common::after {
    margin: 0;
    font-size: 150%;
    color: var(--color-light-gray);

    &:dir(rtl) {
      transform: rotate(180deg);
    }

    @media #{breakpoint('xl')} {
      transform: rotate(90deg);

      &:dir(rtl) {
        transform: rotate(90deg);
      }
    }
  }

  &.is-expanded {
    &.se2-icon-arrow-common::after {
      @media #{breakpoint('xl')} {
        transform: rotate(-90deg);
      }
    }
  }
}

}
