
/* app/stylesheets/mixins/stretchToFullWidth.scss */
/**
 * Mixin stretches element to whole available width of the container
 *
 *  @param {number} $block-start
 *  @param {number} $inline-end
 *  @param {number} $block-end
 *  @param {number} $inline-start
 *  @param {string} $position
 *
 *  Example:
 *    @include stretch-to-full-width();
 *    @include stretch-to-full-width($position: static, $block-start: unset);
 *    @include stretch-to-full-width($block-start: 100px, $inline-end: 200px, $block-end: 30px, $inline-start: 60px);
 *
 */

@mixin stretch-to-full-width($position: absolute, $block-start: 0, $inline-end: 0, $block-end: 0, $inline-start: 0) {
  position: $position;
  inset-block-start: $block-start;
  inset-block-end: $block-end;
  inset-inline-start: $inline-start;
  inset-inline-end: $inline-end;
}

