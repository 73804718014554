
/* app/components/HeaderSE/partials/loginBanner/index.scss */
@import 'app/stylesheets/mixins/stretchToFullWidth.scss';

.se2--header-se--login-banner {
  

& {
  position: relative;
  z-index: #{z('dropdown')};
  pointer-events: visible;

  .sign-in-menu {
    position: absolute;
    inset-inline-end: 0;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    inline-size: 350px;
    padding: 30px;
    box-shadow: rgba(51, 51, 51, 0.14) 0 0 2px 0, rgba(51, 51, 51, 0.1) 0 7px 15px 0;
    background-color: var(--color-white);
    animation: 0.3s ease-in hideList;

    h2 {
      font-weight: bold;
      font-size: 18px;
    }

    .description {
      inline-size: 100%;
      margin-block-start: 0;
      margin-block-end: var(--content-padding-block);
      margin-bottom: 10px;
      color: var(--color-dark-gray);

      p,
      ul,
      ol {
        margin-block-start: 20px;
        margin-block-end: 20px;

        &:first-child {
          margin-block-end: 0;
        }

        &:last-child {
          margin-block-end: 0;
        }
      }

      li {
        justify-content: left;
        align-items: center;
        list-style-type: disc;
        padding-inline: 5px;
        margin: 10px 0 10px 5px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .login-register-menu {
      display: flex;
      flex-wrap: wrap;

      a {
        display: inline-block;
        padding-top: 10px;
      }
    }

    .login-menu-primary-button {
      justify-content: left;
      align-items: center;
      block-size: 40px;
      border-radius: 2px;
      padding-inline: 10px;
      margin: 10px 30px 10px 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-decoration: none;
      color: var(--color-white);
      background-color: var(--color-sky-blue);

      &:hover {
        background-color: var(--color-sky-blue-light);
      }
    }

    .login-menu-secondary-link,
    .login-menu-tertiary-link {
      justify-content: left;
      align-items: center;
      block-size: 40px;
      padding-inline: 10px;
      margin: 10px 0 -10px -10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      inset-block-start: -5px;
      inset-inline-end: 60px;
      display: block;
      block-size: 8px;
      inline-size: 8px;
      box-shadow: 1px -1px 2px 0 rgba(51, 51, 51, 0.14);
      background-color: var(--color-white);
      transform: rotate(-55deg) skewY(22deg);
    }
  }

  &.open {
    .sign-in-menu {
      display: flex;
      animation: 0.3s ease-in showList;
    }
  }
}

}
