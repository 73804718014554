
/* app/components/HeaderSE/partials/menuSectionTopLink/index.scss */

.se2--header-se--menu-section-top-link {
  & {
  html & {
    padding: 10px;
    margin-inline-start: 0;
    margin-inline-end: 10px;
  }

  display: block;
  inline-size: 100%;
  text-decoration: none;
  background-color: var(--color-footer-gray);

  svg {
    block-size: 20px;
    inline-size: 20px;

    &:dir(rtl) {
      transform: rotate(180deg);
    }

    &.openInNewWindow:dir(rtl) {
      transform: rotate(270deg);
    }
  }
}

}
