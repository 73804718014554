
/* app/components/HeaderSE/partials/search/index.scss */

.se2--header-se--search {
  & {
  html .search-menu-wrapper:not(.menu-wrapper-apc) > & {
    border-block-start: 1px solid var(--color-footer-gray);
    padding-block: 8px;
    padding-inline: 12px;

    @media #{breakpoint('xl')} {
      border: 0;
      padding: 0;
    }
  }

  html & {
    &.show {
      display: block;

      @media #{breakpoint('xl')} {
        display: flex;
      }
    }

    &.show-enabled {
      @media #{breakpoint('xl')} {
        display: flex;
      }
    }
  }

  #GlobalSearchApp {
    position: absolute;
    inset-block-start: 12px;
    inset-inline-end: 98px;
    z-index: #{z('zero')};
    display: block;
    flex-grow: 1;
    block-size: 30px;
    border: none;

    .hide-mobile {
      display: none;
    }

    div:first-child {
      margin-block-start: 5px;
    }

    button:not(.search-reset):not(.search-submit) {
      padding: 0 0 3px 0;
      cursor: pointer;
    }

    svg {
      block-size: 20px;
      inline-size: 20px;
      color: var(--color-text-gray);
    }

    @media #{breakpoint('xl')} {
      position: relative;
      inset-block-start: unset;
      inset-inline-end: unset;
      z-index: #{z('above')};

      .hide-mobile {
        display: flex;
      }

      div:first-child {
        margin-block-start: 0;
      }

      button:not(.search-reset):not(.search-submit) {
        padding: 0;
      }
    }
  }

  .shopping-cart.btn-nav {
    display: none;

    [data-count='0'] .cart-count {
      display: none;
    }

    @media #{breakpoint('xl')} {
      display: flex;
      justify-content: center;
      align-items: center;
      block-size: 36px;
      inline-size: 36px;
      border-radius: 4px;
      padding: 0;
      margin-inline-start: 16px;
      background-color: var(--color-white);

      svg {
        block-size: 20px;
        inline-size: 20px;
      }
    }
  }

  // style for search
  .search,
  .sdl-header-se_search {
    &-layer {
      display: flex;
      flex-grow: 1;
    }

    &-bar {
      position: relative; // to position reset btn
      box-sizing: border-box;
      display: flex;
      flex-grow: 1;
      block-size: 44px;
      border: 1px solid var(--color-light-gray);
      border-radius: 4px;
      background-color: var(--color-white);

      &:hover {
        border: 1px solid var(--color-dark-gray);
      }

      @media #{breakpoint('xl')} {
        block-size: 40px;
        max-inline-size: 700px;

        .transparent.reduced &,
        .se2-accessibility & {
          background-color: var(--color-white);

          &:hover {
            background-color: var(--color-white);
          }
        }
      }
    }

    &-field {
      flex-grow: 1;
      order: 1;
      border: none;
      padding-block-start: 5px;
      padding-block-end: 5px;
      padding-inline-start: 40px;
      padding-inline-end: 10px;
      font-size: 16px;
      text-overflow: ellipsis;
      background-color: transparent;

      @media #{breakpoint('xl')} {
        order: 0;
        padding-inline-start: 12px;

        .transparent & {
          color: var(--color-text-gray);

          &::placeholder {
            color: var(--color-dark-gray);
          }
        }

        .transparent.reduced &,
        .se2-accessibility & {
          color: var(--color-black);

          &::placeholder {
            color: initial;
          }
        }
      }

      ::placeholder,
      :-ms-input-placeholder,
      ::-ms-input-placeholder {
        color: var(--color-light-gray);
        opacity: 1;
      }

      &::-webkit-search-cancel-button {
        display: none;
      }

      &::-ms-clear {
        display: none;
      }
    }

    &-reset {
      position: absolute;
      inset-block-start: 0;
      inset-block-end: 0;
      inset-inline-end: 36px;
      display: none; // should be shown on valid input
      align-items: center;
      border: none;
      padding-inline-start: 10px;
      padding-inline-end: 10px;
      color: var(--color-light-gray);
      background: transparent;

      &:hover {
        color: var(--color-dark-gray);
      }

      @media #{breakpoint('xl')} {
        inset-block-start: 40px;
      }

      svg {
        block-size: 14px;
        inline-size: 14px;
      }
    }

    &-submit {
      position: absolute;
      order: 0;
      block-size: 38px;
      inline-size: 40px;
      border: none;
      padding: 0;
      color: var(--color-light-gray);
      background-color: transparent;
      cursor: pointer;

      @media #{breakpoint('xl')} {
        position: static;
        order: 1;
        border-inline-start: 1px solid var(--color-light-gray);
        border-color: var(--color-dark-gray);
        color: var(--color-white);
        background-color: var(--color-dark-gray);
      }

      &:hover {
        border-radius: 4px;
        color: var(--color-text-gray);

        @media #{breakpoint('xl')} {
          border-inline-start: 1px solid var(--color-dark-gray);
          color: var(-- color-dark-gray);
          background-color: var(--color-white);
        }
      }

      >svg {
        block-size: 20px;
        inline-size: 20px;
      }
    }

    // use additional class for ie due to incorrect work with update valid/invalid styles
    &-field:valid ~ .sdl-header-se_search-reset,
    .ie &-field.valid ~ .sdl-header-se_search-reset {
      display: flex;
    }

    .ie &-field:not(.valid) ~ .sdl-header-se_search-reset {
      display: none;
    }

    .ie &-field:not(.valid) ~ .sdl-header-se_search-submit {
      color: var(--color-light-gray); // overwrite rule above

      &:hover {
        color: var(--color-life-green);

        .se2-accessibility & {
          color: var(--color-text-gray);
        }
      }
    }
  }
}

}
