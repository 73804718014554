
/* app/components/HeaderSE/partials/signInForAPC/index.scss */

.se2--header-se--sign-in-for-apc {
  & {
  position: relative;
  z-index: #{z('dropdown')};
  pointer-events: visible;

  .sign-in-menu {
    position: absolute;
    inset-inline-end: 0;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    block-size: 155px;
    inline-size: 230px;
    box-shadow: rgba(51, 51, 51, 0.14) 0 0 2px 0, rgba(51, 51, 51, 0.1) 0 7px 15px 0;
    background-color: var(--color-white);
    animation: 0.3s ease-in hideList;

    .sign-in-link,
    .register-now-link {
      display: flex;
      justify-content: center;
      align-items: center;
      block-size: 40px;
      inline-size: calc(100% - 30px);
      padding-block: 0;
      padding-inline: 20px;
      margin: 15px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-decoration: none;
    }

    .sign-in-link {
      color: var(--color-white);
      background-color: var(--color-sky-blue);

      &:hover {
        background-color: var(--color-sky-blue-light);
      }
    }

    .register-now-link {
      border: 1px solid var(--color-sky-blue);
      color: var(--color-sky-blue);
      background-color: var(--color-white);

      &:hover {
        border-width: 2px;
      }
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 15px;
      color: var(--color-dark-gray);

      &::before,
      &::after {
        content: '';
        flex-grow: 1;
        block-size: 1px;
        background-color: var(--color-footer-gray);
      }

      &::before {
        margin-inline-end: 10px;
      }

      &::after {
        margin-inline-start: 10px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      inset-block-start: -5px;
      inset-inline-end: 60px;
      display: block;
      block-size: 9px;
      inline-size: 9px;
      box-shadow: 1px -1px 2px 0 rgba(51, 51, 51, 0.14);
      background-color: var(--color-white);
      transform: rotate(-55deg) skewY(22deg);
    }
  }

  &.open {
    .sign-in-menu {
      display: flex;
      animation: 0.3s ease-in showList;
    }
  }
}

}
